<template>
  <div class="oc-reply full-page">
    <CommonTitle :titles="titles"/>
    <ReplyQuery
      :loading="loading"
      :downloading="downloading"
      @on-search="onSearch" @reset="onSearch" @on-export="onExport"/>

    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" :bordered="false"
             row-key="id" class="table small-cell-table ph-20">
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="deleteRecord(record)">删除</a-button>
      </template>

      <template slot="footer">
        <Pagination
          v-if="pagination && pagination.total"
          :pagination="pagination"
          class="pagination"
          @change="onPageChange"
          @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>
  </div>
</template>

<script>
import {mapState} from 'vuex';
// import * as moment from 'moment';

import Common from '@/utils/Common';
import {deleteReply, getReplyList, getReplyListDownload} from '@/api/onlineCommunity/Index';

import CommonTitle from '@components/CommonTitle';
import Pagination, {getPagination} from '@components/Pagination';
import ReplyQuery from '@views/onlineCommunity/components/ReplyQuery';

const columns = [
  {
    width: '80px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'content',
    key: 'content',
    title: '回复内容',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'user_data.truename',
    key: 'user_data.truename',
    title: '回复对象',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'created_user_data.truename',
    key: 'created_user_data.truename',
    title: '发布人',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'created_at_1',
    key: 'created_at_1',
    title: '发布时间',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  name: 'Reply',
  components: {CommonTitle, Pagination, ReplyQuery},
  computed: {
    ...mapState({
      query: state => state.OnlineCommunity.replyQuery,
    }),
  },
  data() {
    return {
      titles: [{title: '问题列表'}, {title: '回答列表'}, {title: '评论列表'}, {title: '回复列表'}],
      loading: false,
      downloading: false,
      qid: 0,
      aid: 0,
      cid: 0,

      columns: columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    const query = this.$route.query;
    this.qid = query?.qid || 0;
    this.aid = query?.aid || 0;
    this.cid = query?.cid || 0;

    this.onSearch();
  },
  methods: {
    formatDateTime(time) {
      if (!time) {return '--';}
      return Common.formatDateTime(time * 1000, true);
    },

    async onExport() {
      if (this.downloading || !this.cid) {return;}
      this.downloading = true;

      const params = this.getParams(this.pagination.current, this.pagination.pageSize);
      params.down = 1;

      const data = await getReplyListDownload(params).finally(() => this.downloading = false).catch(() => this.downloading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || `导出失败`);
      }
      this.$message.success(data?.message || '导出成功');
    },

    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (this.data?.length - 1 <= 0 && this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
        this.getData(this.pagination.current > 1 ? (this.pagination.current - 1) : 1, this.pagination.pageSize);
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (!this.cid) {return;}
      if (this.loading) { return; }
      this.loading = true;

      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = this.getParams(page, pageSize);

      const data = await getReplyList(params).finally(() => this.loading = false);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '获取列表失败');
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize) {
      const params = {page: page, per_page: pageSize, comment_id: this.cid};
      this.query?.replyUserName && (params.comment_true_name = this.query.replyUserName);
      this.query?.range && this.query.range[0] && (params.start_day = this.query.range[0].format('YYYY-MM-DD'));
      this.query?.range && this.query.range[1] && (params.end_day = this.query.range[1].format('YYYY-MM-DD'));

      return params;
    },

    deleteRecord(record) {
      this.$confirm({
        content: () => <div>确认删除该回复？</div>,
        onOk: () => this.confirmDeleteRecord(record),
      });
    },
    async confirmDeleteRecord(record) {
      const data = await deleteReply({comment_id: record.id});
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '删除回复失败');
      }
      this.$message.success(data?.message || '删除成功');
      this.onRefreshPage();
    },
  },
};
</script>

<style scoped lang="scss">
.oc-reply {
}
</style>
