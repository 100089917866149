<template>
  <div class="oc-report-detail full-page">
    <CommonTitle :titles="titles">
    </CommonTitle>
    <a-form-model
      ref="ruleForm"
      :model="reportDetail"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="举报人" v-if="reportDetail.user_data">
        <a-input
          v-model="reportDetail.user_data.truename"
          :disabled="true"
        />
      </a-form-model-item>
      <a-form-model-item label="举报理由" :wrapperCol="textWrapperCol">
        <a-tag>{{reportDetail.report_type_title}}</a-tag>
        <a-textarea
          v-model="reportDetail.content"
          :disabled="true"
        />
      </a-form-model-item>
      <a-form-model-item label="被举报内容类型">
        <a-input
          v-model="reportDetail.big_type_title"
          :disabled="true"
        />
      </a-form-model-item>
      <a-form-model-item label="被举报内容ID">
        <a-input
          v-model="reportDetail.relation_id"
          :disabled="true"
        />
      </a-form-model-item>
      <a-form-model-item label="被举报内容" :wrapperCol="textWrapperCol" v-if="reportDetail.relation_data">
        <a-textarea
          v-model="reportDetail.relation_data.digest"
          :disabled="true"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ offset: 5}">
        <a-button
          type="primary"
          v-if="reportDetail.report_status === 0"
          @click="handleDealReport('1')">
          删除处理
        </a-button>
        <a-button
          type="primary"
          v-if="reportDetail.report_status === 0"
          @click="handleDealReport('2')">
          未违规
        </a-button>
        <a-button
          type="primary"
          v-if="reportDetail.report_status === 0"
          @click="handleDealReport('3')">
          恶意举报
        </a-button>
        <a-button @click="handleReturn">返回列表</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import CommonTitle from '@components/CommonTitle';
import {doGetReportDetail, doUpdateReportStatus} from '@/api/onlineCommunity/Report';
import {mapState} from 'vuex';

export default {
  name: 'ReportDetail',
  components: {
    CommonTitle
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      titles: [{title: '举报管理'}, {title: '举报详情'}],
      labelCol: { span: 4 },
      wrapperCol: { span: 4 },
      textWrapperCol: { span: 8 },
      id: this.$route.query.id,
      reportDetail: {
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.handleGetReportDetail();
    }
  },
  destroyed() {
  },
  methods: {
    // 获取举报详情
    async handleGetReportDetail() {
      const data = await doGetReportDetail(this.id);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.reportDetail = data?.data || {};
    },
    handleReturn() {
      this.$router.push('/index/onlineCommunity/report');
    },
    handleDealReport(type) {
      let _self = this;
      if (type === '1' || type === '3') {
        const keyword = type === '1' ? '删除处理' : '恶意举报';
        console.log(keyword);
        this.$confirm({
          content: `确定要进行${keyword}操作吗？`,
          onOk() {
            _self.doDeal(type);
          },
          onCancel() {
            console.log('Cancel');
          },
          class: 'test',
        });

      } else if (type === '2') {
        this.doDeal(type);
      }
    },
    async doDeal(type) {
      console.log(type);
      const params = {};
      switch (type) {
        case '1':
          params.value = '2';
          break;
        case '2':
          params.value = '1';
          break;
        case '3':
          params.value = '3';
          break;
        default:
          params.value = '';
          break;
      }
      params.id = this.id;
      params.field = 'report_status';
      console.log(params);
      const data = await doUpdateReportStatus(params);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      await this.$router.push('/index/onlineCommunity/report');
    },
  },
}
</script>

<style scoped lang="scss">
.oc-report-detail {
}
</style>
