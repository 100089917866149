<template>
  <div class="oc-search-placeholder">
    <CommonTitle :titles="titles"/>

    <a-form layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" :form="form" class="ph-20 mb-10 ">
      <a-form-item label="请输入文案">
        <a-input v-decorator="[
                'placeholder',
                { initialValue: placeholder, rules: [
                  { required: true, message: '请输入文案' },
                  { max: 13, message: '不超过13个字' }
                ]}
              ]" placeholder="请输入文案"></a-input>
      </a-form-item>
      <a-form-item label=" " :colon="false" :label-col="{ span: 4 }">
        <a-button type="primary" @click="save">提交</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import {getSearchPlaceholder, setSearchPlaceholder} from '@/api/onlineCommunity/Index';

import CommonTitle from '@components/CommonTitle';

export default {
  name: 'SearchPlaceholder',
  components: {CommonTitle},
  data() {
    return {
      titles: [{title: '搜索文案设置'}],
      loading: false,

      form: this.$form.createForm(this, {name: 'coordinated'}),

      placeholder: '',
    };
  },
  created() {
    this.getSearchPlaceholder();
  },
  methods: {
    async getSearchPlaceholder() {
      if (this.loading) {return;}
      this.loading = true;

      const data = await getSearchPlaceholder().finally(() => this.loading = false);
      if (!data || data.error_code) {return this.$message.error(data?.message || '获取搜索文案失败');}
      this.placeholder = data?.data?.content || '';
    },
    async setSearchPlaceholder() {
      if (this.loading) {return;}
      this.loading = true;

      const data = await setSearchPlaceholder({content: this.placeholder}).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '设置搜索文案失败');
        return Promise.reject(data);
      }
      this.$message.success(data?.message || '设置成功');
      return Promise.resolve(data);
    },
    save() {
      if (this.loading) {return;}
      this.loading = true;

      this.form.validateFields(async (err, values) => {
        if (err) {
          this.$message.info({content: '请填写必填项'});
          return this.loading = false;
        }
        this.placeholder = values?.placeholder || '';

        this.loading = false;
        this.setSearchPlaceholder();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.oc-search-placeholder {
}
</style>
