import {baseUrl} from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import {downloadFileExtra} from '@/utils/JsDownload';
import {stringify} from "qs";
// import {downloadFileExtra} from '@/utils/JsDownload';

// 回答-回答列表
export function getReportList(params) {
  return request({
    url: '/ask/admin/informs/index',
    method: 'get',
    params,
  });
}
// 更新举报状态
export function doUpdateReportStatus(data) {
  return request({
    url: '/ask/admin/informs/updfield',
    method: 'post',
    data,
  });
}
// 获取举报详情
export function doGetReportDetail(id) {
  return request({
    url: `/ask/admin/informs/${id}`,
    method: 'get',
  });
}
// 获取未处理举报数
export function doGetNoDealNums(params) {
  return request({
    url: '/ask/admin/informs/nodealnum',
    method: 'get',
    params,
  });
}

// 举报-举报列表-导出 excel
export function getReportListDownload(params) {
  let url = `/ask/admin/informs/index`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
