<template>
  <a-select v-model="selectedIds" :allow-clear="true"
            mode="multiple" placeholder="请选择话题" class="select"
            @change="onChange">
    <!-- :show-search="true" :filter-option="false" -->
    <!--<a-spin v-if="loading" slot="notFoundContent" size="small"/>-->

    <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
  </a-select>
</template>

<script>
// import debounce from 'lodash/debounce';
import {mapState} from 'vuex';

// import {getTopicList} from '@/api/onlineCommunity/Index';

export default {
  name: 'SelectTopics',
  model: {
    prop: 'ids',
    event: 'select',
  },
  props: {
    ids: {type: Array, default: () => []},
  },
  computed: {
    ...mapState({
      options: state => state.OnlineCommunity.topicListForSelect,
    }),
  },
  watch: {
    ids(idList) {
      this.selectedIds = idList || [];
    },
  },
  data() {
    // this.getData = debounce(this.getData, 500);
    return {
      loading: false,

      selectedIds: [],
    };
  },
  created() {
    // this.$store.dispatch('OnlineCommunity/getTopicList');
    // this.getData('');
  },
  methods: {
    // async getData(value) {
    //   console.log(value);
    //   this.loading = true;
    //
    //   const data = await getTopicList().finally(() => this.loading = false);
    //   if (!data || data.error_code) {
    //     this.options = [];
    //     return;
    //   }
    //   this.options = data?.data || [];
    // },

    onChange() {
      this.$emit('select', this.selectedIds);
    },
  },

};
</script>

<style scoped lang="scss">
.select {
  min-width: 220px;
}
</style>
