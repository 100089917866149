<template>
  <div class="oc-tag full-page">
    <CommonTitle :titles="titles">
      <div slot="buttons" class="btn-group">
        <a-button @click="addClassification">添加分类</a-button>
        <a-button @click="addTag">添加标签</a-button>
      </div>
    </CommonTitle>
    <div class="tag-list">
      <a-card :loading="loading" v-for="item in classList" :key="item.id">
        <div slot="title" class="tag-title">
          <a-icon type="form" style="cursor: pointer;" @click="handleEditClass(item)" />
          <p>{{item.title}}-优先级:{{item.sort}}</p>
          <a-icon type="delete" style="cursor: pointer;" @click="handleDelClass(item)" />
        </div>
        <a-table :columns="columns" :data-source="item.labels" :pagination="false" bordered
                 row-key="id" class="table small-cell-table">
          <template slot="action" slot-scope="text, record">
            <div class="table-cell-wrapper">
              <a-button type="link" size="small" @click="handleEditTag(record)">编辑</a-button>
              <a-button type="link" size="small" @click="handleDelTag(record)">删除</a-button>
            </div>
          </template>
        </a-table>
      </a-card>
    </div>
    <add-classification
      ref="addClassification"
      @save-class="saveClass"
      @edit-class="editClass"
      :class-page-type="classPageType"
      :class-detail="classDetail"></add-classification>
    <add-tag
      ref="addTag"
      @save-tag="saveTag"
      @edit-tag="editTag"
      :page-type="pageType"
      :tag-detail="tagDetail"
      :class-list="classList"></add-tag>
  </div>
</template>

<script>
import CommonTitle from '@components/CommonTitle';
import AddClassification from "@components/tag/AddClassification";
import {getTagList, deleteTag, saveTagDetail} from '@/api/onlineCommunity/Tags';
import AddTag from "@components/tag/AddTag";
import {mapState} from 'vuex';

const columns = [
  {
    width: '80px',
    align: 'center',
    title: '顺序',
    key: 'number',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    width: '180px',
    align: 'center',
    title: '标签',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '80px',
    align: 'center',
    title: '优先级',
    key: 'sort',
    dataIndex: 'sort',
    scopedSlots: { customRender: 'sort' },
  },
  {
    width: '80px',
    align: 'center',
    title: '内容数',
    key: 'content_num',
    dataIndex: 'content_num',
    scopedSlots: { customRender: 'content_num' },
  },
  {
    width: '150px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Tag',
  components: {
    CommonTitle,
    AddClassification,
    AddTag
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      titles: [{title: '标签管理'}, {title: '标签列表'}],
      columns,
      data: [],
      classList: [],
      cate_level: '',
      nowTag: 0,
      nowClass: 0,
      pageType: '0',
      classPageType: '0',
      tagDetail: {},
      classDetail: {},
      delTagLoading: false,
      loading: false,
    }
  },
  async created() {
    await this.handleGetTagList();
  },
  destroyed() {
  },
  methods: {
    // 获取分类列表
    async handleGetTagList() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getTagList().finally(() => {
        this.loading = false
      });
      if (!data || data.error_code) {
        return;
      }
      console.log(data);
      this.classList = data.data;
    },
    // 添加分类
    addClassification() {
      console.log('添加分类');
      this.nowClass = 0;
      this.classPageType = '0';
      this.classDetail = {};
      this.$refs.addClassification.handleShowModal();
    },
    // 添加标签
    addTag() {
      console.log('添加标签');
      this.nowTag = 0;
      this.pageType = '0';
      this.$refs.addTag.handleShowModal();
    },
    async editTag(item) {
      item.id = this.nowTag;
      console.log(item);
      const data = await saveTagDetail(item);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      this.$refs.addTag?.hideModal();
      await this.handleGetTagList();
    },
    async saveTag(item) {
      item.id = this.nowTag;
      console.log(item);
      const data = await saveTagDetail(item);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      this.$refs.addTag?.hideModal();
      this.tagDetail = {};
      await this.handleGetTagList();
    },
    async editClass(item) {
      item.id = this.nowClass;
      item.cate_pid = '0';
      console.log(item);
      const data = await saveTagDetail(item);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      this.$refs.addClassification?.hideModal();
      await this.handleGetTagList();
    },
    async saveClass(item) {
      item.id = this.nowClass;
      item.cate_pid = '0';
      console.log(item);
      const data = await saveTagDetail(item);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      this.$refs.addClassification?.hideModal();
      await this.handleGetTagList();
    },
    // 编辑分类
    handleEditClass(item) {
      this.nowClass = item.id;
      this.classDetail = item;
      this.classPageType = '1';
      console.log('编辑分类');
      this.$refs.addClassification.handleShowModal();
    },
    // 编辑标签
    handleEditTag(item) {
      this.nowTag = item.id;
      this.tagDetail = item;
      this.pageType = '1';
      console.log('编辑标签');
      this.$refs.addTag.handleShowModal();
    },
    // 删除分类
    handleDelClass({ id }) {
      this.nowClass = id;
      this.cate_level = 'first';
      let _self = this;
      this.$confirm({
        content: '确定要删除该分类吗？',
        onOk() {
          console.log('OK');
          _self.goDelClass();
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    async goDelClass() {
      console.log('删除啦');
      const params = {};
      params.cate_level = this.cate_level;
      const data = await deleteTag(this.nowClass, params);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      await this.handleGetTagList();
    },
    // 删除标签
    handleDelTag({ id }) {
      this.nowTag = id;
      this.cate_level = 'second';
      let _self = this;
      this.$confirm({
        content: '确定要删除该标签吗？',
        onOk() {
          console.log('OK');
          _self.goDel();
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    async goDel() {
      console.log('删除啦');
      const params = {};
      params.cate_level = this.cate_level;
      const data = await deleteTag(this.nowTag, params);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      await this.handleGetTagList();
    }
  },
}
</script>

<style scoped lang="scss">
.oc-tag {
  .tag-list {
    margin-top: 20px;
  }
  .tag-title {
    display: flex;
    align-items: center;
    p {
      margin: 0 10px 0 10px;
    }
  }
}
</style>
