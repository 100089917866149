<template>
  <a-modal title="修改积分规则"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :centered="true"
           :closable="true"
           :destroy-on-close="true"
           :after-close="reset"
           @ok="saveRule"
           @cancel="close">
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" class="ph-20 mb-10">
      <a-form-item label="动作">
        <span>{{ title }}</span>
      </a-form-item>

      <a-form-item label="奖励积分">
        <a-input-number v-model="score" :max="9999" placeholder="输入奖励积分"></a-input-number>
      </a-form-item>

      <a-form-item label="每日最大次数1">
        <a-input-number v-model="days" :min="0" :max="9999" placeholder="每日最大次数" class="large-input"></a-input-number>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {setAccumulatedRule} from '@/api/onlineCommunity/Index';

export default {
  name: 'SetAccumulatedRuleModal',
  data() {
    return {
      loading: false,
      shown: false,
      form: this.$form.createForm(this, {name: 'coordinated'}),

      id: 0,
      title: '',
      score: 0,
      days: 0,
    };
  },
  methods: {
    show({id, title, score, day_num} = {}) {
      this.id = id;
      this.title = title;
      this.score = score;
      this.days = day_num;

      this.shown = true;
    },
    close({success}) {
      this.shown = false;
      success && this.$emit('changed', {rule: this.rule});
    },

    reset() {
      this.id = 0;
      this.title = '';
      this.score = 0;
      this.days = 0;
    },

    async saveRule() {
      if (this.loading) {return;}
      this.loading = true;

      const data = await setAccumulatedRule(this.id, {
        score: this.score,
        day_num: this.days,
      }).finally(() => this.loading = false);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '修改失败');
      }
      this.$message.success(data?.message || '修改成功');
      this.close({success: true});
    },
  },
};
</script>

<style scoped lang="scss">
  .large-input {
    width: 200px!important;
  }
</style>
