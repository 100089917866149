<template>
  <a-modal :title="classificationType === '0'? '创建标签' : '编辑标签'"
           :visible="shown"
           :confirm-loading="confirmLoading"
           :mask-closable="false"
           :width="600"
           :centered="true"
           :destroy-on-close="true"
           wrap-class-name="add-topic-modal"
           @ok="confirm"
           @cancel="close">
    <a-form-model
      ref="topicForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="title" label="标签名称" prop="title">
        <a-input
          v-model="form.title"
          placeholder="请输入标签名称"
        />
      </a-form-model-item>
      <a-form-model-item ref="cate_pid" label="标签分类" prop="cate_pid">
        <a-select v-model="form.cate_pid" placeholder="请选择分类">
          <a-select-option v-for="item in classList" :key="item.id" :value="item.id">
            {{item.title}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="sort" label="优先级" prop="sort">
        <a-input-number
          v-model="form.sort"
          :disabled="readOnly"
          :min="1"
          :max="9999"
          :allow-clear="true"></a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
  export default {
    name: "AddTopicModel",
    props: {
      classList: {
        type: Array,
        default() {
          return []
        }
      },
      tagDetail: {
        type: Object,
        default() {
          return {}
        }
      },
      pageType: {
        type: String,
        default() {
          return ''
        }
      },
    },
    watch: {
      tagDetail: {
        handler(newVal, oldVal) {
          if (oldVal !== newVal) {
            this.form.title = newVal.title;
            this.form.cate_pid = newVal.category_id;
            this.form.sort = newVal.sort;
          }
        },
        immediate: true
      }
    },
    data() {
      return {
        classificationType: '0',
        shown: false,
        readOnly: false,
        confirmLoading: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 10 },
        form: {
          title: '', // 话题标题
          cate_pid: '',
          sort: undefined
        },
        rules: {
          title: [
            { required: true, message: '请填写标签名称', trigger: 'blur' },
            { min: 1, max: 10, message: '标签名称长度在 1 到 10 之间', trigger: 'blur' },
          ],
          cate_pid: [{ required: true, message: '请选择标签分类', trigger: 'change' }],
          sort: [
            { required: true, message: '请填写优先级', trigger: 'blur' }
          ],
        },
      }
    },
    methods: {
      // 展示弹出框
      handleShowModal() {
        this.shown = true;
      },
      hideModal() {
        this.form.title = '';
        this.form.cate_pid = '';
        this.form.sort = undefined;
        this.shown = false;
      },
      // 确认
      confirm() {
        this.$refs.topicForm.validate(valid => {
          if (valid) {
            if (this.pageType === '0') {
              this.goAddTag();
            } else if (this.pageType === '1') {
              this.goEditTag();
            }
          } else {
            return false;
          }
        });
      },
      // 添加标签
      goAddTag() {
        console.log('新增啦');
        this.$emit('save-tag', this.form);
      },
      // 修改标签
      goEditTag() {
        console.log('修改啦');
        this.$emit('edit-tag', this.form);
      },
      // 取消
      close() {
        this.form.title = '';
        this.form.cate_pid = '';
        this.form.sort = undefined;
        this.shown = false;
      }
    }
  }
</script>

<style scoped>

</style>
