<template>
  <div class="oc-accumulated-rule full-page">
    <CommonTitle :titles="titles"/>

    <div class="flex flex-start ph-20 mb-20 small-text">注意，内容审核通过后才能够获得积分</div>

    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" :bordered="false"
             row-key="id" class="table small-cell-table ph-20">
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="editRule(record)">编辑</a-button>
      </template>

      <template slot="footer">
        <Pagination
          v-if="pagination && pagination.total"
          :pagination="pagination"
          class="pagination"
          @change="onPageChange"
          @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>

    <SetAccumulatedRuleModal ref="setRuleModalRef" @changed="getData"/>
  </div>
</template>

<script>
import Common from '@/utils/Common';
import {getAccumulatedRuleList} from '@/api/onlineCommunity/Index';

import CommonTitle from '@components/CommonTitle';
import Pagination, {getPagination} from '@components/Pagination';
import SetAccumulatedRuleModal from '@views/onlineCommunity/components/SetAccumulatedRuleModal';

const columns = [
  {
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '用户行为',
  },
  {
    align: 'center',
    dataIndex: 'score',
    key: 'score',
    title: '奖励积分',
  },
  {
    align: 'center',
    dataIndex: 'day_num',
    key: 'day_num',
    title: '每日获得上限次数',
  },
  {
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  name: 'AccumulatedRule',
  components: {CommonTitle, Pagination, SetAccumulatedRuleModal},
  data() {
    return {
      titles: [{title: '积分配置'}],
      loading: false,

      columns: columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      if (this.loading) { return; }
      this.loading = true;

      const data = await getAccumulatedRuleList().finally(() => this.loading = false);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '获取列表失败');
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    editRule(record) {
      this.$refs?.setRuleModalRef?.show && this.$refs.setRuleModalRef.show(record);
    },
  },
};
</script>

<style scoped lang="scss">
.small-text {
  font-size: 12px;
}
</style>
