<template>
  <div class="oc-topic full-page">
    <CommonTitle :titles="titles" :all-num="officialNum">
      <div slot="buttons" class="btn-group">
        <a-button @click="addTeacher">添加讲师</a-button>
      </div>
    </CommonTitle>
    <div class="search row-wrap">
      <div class="select-date mb-10">
        <span>查询时间：</span>
        <select-date ref="selectDate" @select-date="handleSelectDate"></select-date>
      </div>
      <div class="search-btn mb-10">
        <a-button type="primary" @click="handleSearch">搜索</a-button>
        <a-button @click="handleReset">重置</a-button>
      </div>
    </div>
    <div class="row">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="handleRemoveOfficial(record)">移除</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
    <add-teacher
      ref="addTeacher"
      @do-save-official="doSaveOfficial"
    ></add-teacher>
  </div>
</template>

<script>
  import CommonTitle from '@components/CommonTitle';
  import SelectDate from "@components/DatePicker";
  import AddTeacher from "@components/official/AddTeacher";
  import Pagination, {getPagination} from '@components/Pagination';
  import {getOfficialList, deleteOfficial, saveOfficial} from '@/api/onlineCommunity/Official';
  import Common from '@/utils/Common';
  import {mapState} from 'vuex';

  const columns = [
    {
      width: '120px',
      align: 'center',
      title: 'UID',
      dataIndex: 'user_id',
      key: 'user_id',
    },
    {
      width: '120px',
      align: 'center',
      title: '姓名',
      dataIndex: 'truename',
      key: 'truename',
    },
    {
      width: '120px',
      align: 'center',
      title: '电话',
      dataIndex: 'username',
      key: 'username',
      scopedSlots: { customRender: 'username' },
    },
    {
      width: '80px',
      align: 'center',
      title: '回答数',
      key: 'answer_num',
      dataIndex: 'answer_num',
      scopedSlots: { customRender: 'answer_num' },
    },
    {
      width: '80px',
      align: 'center',
      title: '受邀数',
      key: 'be_invited_num',
      dataIndex: 'be_invited_num',
      scopedSlots: { customRender: 'be_invited_num' },
    },
    {
      width: '80px',
      align: 'center',
      title: '回答获赞数',
      key: 'answer_praise_num',
      dataIndex: 'answer_praise_num',
      scopedSlots: { customRender: 'answer_praise_num' },
    },
    {
      width: '80px',
      align: 'center',
      title: '回答被评论次数',
      key: 'answer_comment_num',
      dataIndex: 'answer_comment_num',
      scopedSlots: { customRender: 'answer_comment_num' },
    },
    {
      width: '150px',
      align: 'center',
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
    },
  ];

  export default {
    name: 'Topic',
    components: {
      CommonTitle,
      SelectDate,
      AddTeacher,
      Pagination
    },
    computed: {
      ...mapState({}),
    },
    data() {
      return {
        columns,
        loading: false,
        officialNum: 0,
        listDefaultShownCount: 2,
        titles: [{title: '官方身份管理'}, {title: '身份列表'}],
        pagination: getPagination(),
        start_day: '',
        officialId: 0,
        end_day: '',
        data: [],
        selectDate: '' // 查询时间
      }
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      handleSearch() {
        this.getData();
      },
      async getData(page, pageSize) {
        page = page || 1;
        pageSize = pageSize || this.pagination.defaultPageSize;

        const params = {
          start_day: this.start_day,
          end_day: this.end_day,
          page: page,
          per_page: pageSize,
        };
        if (this.loading) {
          return;
        }
        this.loading = true;

        const data = await getOfficialList(params).finally(() => {
          this.loading = false
        });
        if (!data || data.error_code) {
          return;
        }
        data.data.forEach((item) => {
          item.truename = item.user_data.truename || '';
          item.username = item.user_data.username || '';
        })
        console.log(data);
        this.data = data.data;
        const pagination = data?.meta?.pagination || {};
        this.officialNum = pagination.total;
        this.pagination = Common.updatePagination(this.pagination, pagination);
      },
      onPageChange(page, pageSize) {
        this.getData(page, pageSize);
      },
      onSizeChange(current, size) {
        this.getData(1, size);
      },
      handleReset() {
        this.start_day = '';
        this.end_day = '';
        this.$refs.selectDate.getDate();
      },
      // 添加讲师
      addTeacher() {
        console.log('添加讲师');
        this.$refs.addTeacher?.handleShowModal();
      },
      // 选择日期
      handleSelectDate(val) {
        this.start_day = val[0] || '';
        this.end_day = val[1] || '';
        this.getData();
      },
      // 移除身份
      handleRemoveOfficial({ id }) {
        this.officialId = id;
        let _self = this;
        this.$confirm({
          content: '确定要移除该身份吗？',
          onOk() {
            console.log('OK');
            _self.goRemove();
          },
          onCancel() {
            console.log('Cancel');
          },
          class: 'test',
        });
      },
      async doSaveOfficial(item) {
        console.log(item);
        const data = await saveOfficial(item);
        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        this.$message.success(data.message);
        this.$refs.addTeacher?.handleSaveModal();
        await this.getData();
      },
      async goRemove() {
        console.log('移除啦');
        const params = {};
        params.id = this.officialId;
        console.log(params);
        const data = await deleteOfficial(params);
        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        this.$message.success(data.message);
        await this.getData();
      },
    },
  }
</script>

<style scoped lang="scss">
  .oc-topic {
    .search {
      display: flex;
      align-items: center;
      padding: 3px 15px 5px 30px;
      .select-date {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }

    & .row-wrap {
      flex-wrap: wrap;
    }
  }
</style>
