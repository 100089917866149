<template>
  <div class="select" @click="showModal">
    <span v-if="!tags || !tags.length" class="placeholder">请选择标签</span>
    <a-tag v-for="tag of tags" :key="tag.id" :closable="true"
           class="tag" @close="removeTag(tag)">
      {{ tag.title }}
    </a-tag>

    <span class="clear-btn" @click.stop="clearTag">
      <i aria-label="图标: close-circle" class="anticon anticon-close-circle ant-select-clear-icon"><svg
        viewBox="64 64 896 896" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"
        focusable="false" class=""><path
        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg></i>
    </span>

    <SelectTagsModal ref="selectTagsModalRef" title="请选择标签" @save="saveTags"/>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import SelectTagsModal from '@views/onlineCommunity/components/SelectTagsModal';

export default {
  name: 'SelectTag',
  components: {SelectTagsModal},
  model: {
    prop: 'ids',
    event: 'select',
  },
  props: {
    ids: {type: Array, default: () => []},
  },
  computed: {
    ...mapState({
      tagListForSelect: state => state.OnlineCommunity.tagListForSelect,
    }),
    tags() {
      if (!this.tagListForSelect?.length) {return [];}
      if (!this.ids?.length) {return [];}
      const list = [];
      this.tagListForSelect.forEach(item => {
        if (item?.labels?.length) {
          list.push(...item.labels.filter(i => this.ids.includes(i.id)));
        }
      });
      return list;
    },
  },
  data() {
    return {
      names: '',
    };
  },
  methods: {
    showModal() {
      this.$refs?.selectTagsModalRef?.show && this.$refs.selectTagsModalRef.show({
        tagIds: this.ids,
      });
    },

    saveTags(ev) {
      this.names = ev.tagNameStr || '';
      this.changed(ev.tagIds || []);
    },

    changed(ids) {
      this.$emit('select', ids);
    },

    removeTag(tag) {
      this.changed(this.tags.map(i => i.id).filter(i => i !== tag.id));
    },

    clearTag() {
      this.changed([]);
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  position: relative;
  min-width: 220px;
  min-height: 32px;
  padding-left: 5px;
  padding-right: 20px;
  line-height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  &:hover {
    border-color: #f22930;
    border-right-width: 1px !important;

    .tag + .clear-btn {
      opacity: 1;
    }
  }

  .placeholder {
    color: #D6C5BF;
  }

  .clear-btn {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    line-height: 12px;
    text-align: center;
    text-transform: none;
    background: #fff;
    cursor: pointer;
    user-select: none;
    opacity: 0;
  }
}

.tag {
  height: 24px;
  font-size: 14px;
}
</style>
