import { render, staticRenderFns } from "./SelectTagsModal.vue?vue&type=template&id=87cc9c56&scoped=true&"
import script from "./SelectTagsModal.vue?vue&type=script&lang=js&"
export * from "./SelectTagsModal.vue?vue&type=script&lang=js&"
import style0 from "./SelectTagsModal.vue?vue&type=style&index=0&id=87cc9c56&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87cc9c56",
  null
  
)

export default component.exports