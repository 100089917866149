<template>
  <a-form :form="form" layout="inline" class="ph-20 mb-10 quiz-query">
    <a-form-item label="问题">
      <a-input v-model="quizName" placeholder="请输入问题题干" :allow-clear="true" class="input"></a-input>
    </a-form-item>

    <a-form-item label="回答条数">
      <CountFilter v-model="countFilter"/>
    </a-form-item>

    <a-form-item label="审核状态">
      <TristateSelection v-model="auditingStatus"/>
    </a-form-item>

    <a-form-item label="话题">
      <SelectTopics v-model="topicIds"/>
    </a-form-item>

    <a-form-item label="标签">
      <SelectTag v-model="tagIds"/>
    </a-form-item>

    <a-form-item label="题主姓名">
      <a-input v-model="quizUserName" placeholder="请输入题主姓名" :allow-clear="true" class="input"></a-input>
    </a-form-item>

    <a-form-item label="发布时间">
      <a-range-picker v-model="range" :allow-clear="true"></a-range-picker>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" @click="onSearch">查询</a-button>
    </a-form-item>

    <a-form-item>
      <a-button @click="reset">重置</a-button>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" @click="exportExcel">导出</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import CountFilter from '@components/onlineCommunity/CountFilter';
import SelectTag from '@views/onlineCommunity/components/SelectTag';
import SelectTopics from '@views/onlineCommunity/components/SelectTopics';
import TristateSelection from '@components/onlineCommunity/TristateSelection';

export default {
  name: 'QuizQuery',
  components: {CountFilter, SelectTag, SelectTopics, TristateSelection},
  props: {
    loading: {type: Boolean, default: false},
    downloading: {type: Boolean, default: false},
  },
  data() {
    return {
      form: this.$form.createForm(this, {name: 'coordinated'}),

      range: undefined,

      quizName: '',
      countFilter: undefined,
      auditingStatus: undefined,
      quizUserName: '',
      topicIds: [],
      tagIds: [],
    };
  },
  created() {
    this.getQuery();
  },
  beforeDestroy() {
    this.quizName = '';
    this.countFilter = undefined;
    this.auditingStatus = undefined;
    this.topicIds = [];
    this.quizUserName = '';
    this.range = undefined;
    this.$store.commit('OnlineCommunity/resetQuizQuery');
  },
  methods: {
    getQuery() {
      const query = this.$store.state.OnlineCommunity?.quizQuery || {};

      this.quizName = query.quizName || '';
      this.countFilter = {filter: query.answerFilter, count: query.answerCount};
      this.range = query.range || [];
      this.auditingStatus = query.auditingStatus || 0;
      this.topicIds = query.topic && query.topic.split(',').map(i => +i) || [];
      this.tagIds = query.tag && query.tag.split(',').map(i => +i) || [];
      this.quizUserName = query.quizUserName || '';
    },
    saveQuery() {
      this.$store.commit('OnlineCommunity/updateQuizQuery', {
        quizName: this.quizName || '',
        answerFilter: this.countFilter?.filter,
        answerCount: this.countFilter?.count,
        auditingStatus: this.auditingStatus,
        topic: this.topicIds?.length ? this.topicIds.join(',') : '',
        tag: this.tagIds?.length ? this.tagIds.join(',') : '',
        quizUserName: this.quizUserName || '',
        range: this.range,
      });
    },

    onSearch() {
      this.saveQuery();
      this.$emit('on-search');
    },
    reset() {
      this.quizName = '';
      this.countFilter = undefined;
      this.auditingStatus = undefined;
      this.topicIds = [];
      this.quizUserName = '';
      this.range = undefined;
      this.$store.commit('OnlineCommunity/resetQuizQuery');
      this.getQuery();
      this.$emit('reset');
    },
    exportExcel() {
      this.saveQuery();
      this.$emit('on-export');
    },
  },
};
</script>

<style scoped lang="scss">
.quiz-query {
}
</style>
