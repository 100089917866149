import request from '@/utils/Request';

// 官方身份列表
export function getOfficialList(params) {
  return request({
    url: `/ask/admin/askofficialuser/index`,
    method: 'get',
    params,
  });
}

// 标签--添加/修改 标签
export function saveOfficial(data) {
  return request({
    url: '/ask/admin/askofficialuser/save',
    method: 'post',
    data,
  });
}

// 标签--删除标签
export function deleteOfficial(data) {
  return request({
    url: '/ask/admin/askofficialuser/delete',
    method: 'post',
    data
  });
}

