<template>
  <a-modal title="问题明细"
           :visible="shown"
           :mask-closable="true"
           :width="600"
           :centered="true"
           :closable="true"
           :destroy-on-close="true"
           :after-close="reset"
           @ok="close"
           @cancel="close">
    <a-list-item>题干：{{ quiz.title || '' }}</a-list-item>
    <a-list-item>内容：{{ quiz.content || '' }}</a-list-item>

    <template slot="footer">
      <a-button type="primary" @click="close">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'QuizDetailModal',
  data() {
    return {
      shown: false,
      quiz: {},
    };
  },
  methods: {
    show({quiz}) {
      this.quiz = quiz;

      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.quiz = {};
    },
  },
};
</script>

<style scoped>

</style>
