<template>
  <a-form :form="form" layout="inline" class="ph-20 mb-10 quiz-query">
    <a-form-item label="评论者">
      <a-input v-model="commentUserName" placeholder="请输入评论者姓名" :allow-clear="true" class="input"></a-input>
    </a-form-item>

    <a-form-item label="发布时间">
      <a-range-picker v-model="range" :allow-clear="true"></a-range-picker>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" @click="onSearch">查询</a-button>
    </a-form-item>

    <a-form-item>
      <a-button @click="reset">重置</a-button>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" @click="exportExcel">导出</a-button>
    </a-form-item>

    <a-form-item>
      <a-button @click="back">返回</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  name: 'CommentQuery',
  props: {
    loading: {type: Boolean, default: false},
    downloading: {type: Boolean, default: false},
  },
  data() {
    return {
      form: this.$form.createForm(this, {name: 'coordinated'}),

      commentUserName: '',
      range: undefined,
    };
  },
  created() {
    this.getQuery();
  },
  beforeDestroy() {
    this.commentUserName = '';
    this.range = undefined;
    this.$store.commit('OnlineCommunity/resetCommentQuery');
  },
  methods: {
    getQuery() {
      const query = this.$store.state.OnlineCommunity?.commentQuery || {};

      this.commentUserName = query.commentUserName || '';
      this.range = query.range || [];
    },
    saveQuery() {
      this.$store.commit('OnlineCommunity/updateCommentQuery', {
        commentUserName: this.commentUserName || '',
        range: this.range,
      });
    },

    onSearch() {
      this.saveQuery();
      this.$emit('on-search');
    },
    reset() {
      this.commentUserName = '';
      this.range = undefined;
      this.$store.commit('OnlineCommunity/resetCommentQuery');
      this.$emit('reset');
    },
    exportExcel() {
      this.saveQuery();
      this.$emit('on-export');
    },

    back() {
      this.commentUserName = '';
      this.range = undefined;
      this.$store.commit('OnlineCommunity/resetCommentQuery');
      this.$router.back();
    },
  },
};
</script>

<style scoped>

</style>
