<template>
  <a-modal :title="title"
           :visible="shown"
           :mask-closable="true"
           :width="600"
           :centered="true"
           :closable="true"
           :destroy-on-close="true"
           :after-close="reset"
           @ok="close"
           @cancel="close">
    <a-list>
      <a-list-item>姓名：{{ trueName }}</a-list-item>
      <a-list-item>手机号：{{ mobile }}</a-list-item>
      <a-list-item>岗位：
        <span v-for="(item, idx) of post" :key="item.postid">
        <template v-if="idx">，</template>
        {{ item.name }}
        </span>
      </a-list-item>
      <a-list-item>经销商：{{ org.name || '' }}</a-list-item>
    </a-list>

    <template slot="footer">
      <a-button type="primary" @click="close">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
import {getUserInfo} from '@/api/onlineCommunity/Index';

export default {
  name: 'UserInfoModal',
  props: {
    title: {type: String, default: ''},
  },
  data() {
    return {
      shown: false,
      userId: '',

      trueName: '',
      mobile: '',
      post: [],
      org: {},
    };
  },
  methods: {
    show({userId}) {
      this.userId = userId;
      this.getUserInfo();

      this.shown = true;
    },
    close() {
      this.shown = false;
    },

    reset() {
      this.userId = '';
      this.trueName = '';
      this.mobile = '';
      this.post = [];
      this.org = {};
    },

    async getUserInfo() {
      const data = await getUserInfo(this.userId);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '获取用户信息失败');
      }

      const result = data?.data || {};
      this.trueName = result?.truename || '';
      this.mobile = result?.member || '';
      this.post = result?.post_data || [];
      this.org = result?.org_data || {};
    },
  },
};
</script>

<style scoped lang="scss">

</style>
