<template>
  <div class="oc-topic full-page">
    <CommonTitle :titles="titles" :all-num="topicNum">
      <div slot="buttons" class="btn-group">
        <a-button @click="addTopic">创建话题</a-button>
      </div>
    </CommonTitle>
    <div class="search row-wrap">
      <div class="select-date mb-10">
        <span>查询时间：</span>
        <select-date ref="selectDate" @select-date="handleSelectDate"></select-date>
      </div>
      <div class="search-btn mb-10">
        <a-button type="primary" @click="handleSearch">搜索</a-button>
        <a-button @click="handleReset">重置</a-button>
      </div>
    </div>
    <div class="row">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
<!--        <template slot="title" slot-scope="text, record">-->
<!--          <div class="table-cell-wrapper">-->
<!--            <div v-for="(item, index) of text" :key="item.id">-->
<!--              <template v-if="record.allOrgShown || index < listDefaultShownCount">-->
<!--                <span>{{ item.title }}</span>-->
<!--              </template>-->
<!--            </div>-->

<!--            <template v-if="text.length > listDefaultShownCount">-->
<!--              <a-button type="link" size="small" @click="toggleAllOrgShown(record)">-->
<!--                <span v-if="record.allOrgShown">收起全部</span>-->
<!--                <span v-else>显示全部</span>-->
<!--              </a-button>-->
<!--            </template>-->
<!--          </div>-->
<!--        </template>-->
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="handleEditTopic(record)">编辑</a-button>
<!--            <a-button type="link" size="small" @click="handleOffTopic(record)">下架</a-button>-->
<!--            <a-button type="link" size="small" @click="handleShelvesTopic(record)">上架</a-button>-->
            <a-button type="link" size="small" @click="handleDelTopic(record)">删除</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
    <add-topic-model
      ref="addTopic"
      :page-type="pageType"
      :topic-title="topicTitle"
      @add-topic="doSaveTopic"
      @edit-topic="doEditTopic"
    ></add-topic-model>
  </div>
</template>

<script>
import CommonTitle from '@components/CommonTitle';
import SelectDate from "@components/DatePicker";
import AddTopicModel from "@components/topic/AddTopicModel";
import Pagination, {getPagination} from '@components/Pagination';
import {getTopicList, getTopicListActivity, deleteTopic, saveTopicDetail} from '@/api/onlineCommunity/Topics';
import Common from '@/utils/Common';
import {mapState} from 'vuex';

const isActivity = true;

const columns = [
  {
    width: '180px',
    align: 'center',
    title: '话题名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '80px',
    align: 'center',
    title: '问题数量',
    key: 'ask_num',
    dataIndex: 'ask_num',
    scopedSlots: { customRender: 'ask_num' },
  },
  {
    width: '120px',
    align: 'center',
    title: '创建时间',
    dataIndex: 'created_at_1',
    key: 'created_at_1',
    scopedSlots: { customRender: 'created_at_1' },
  },
  {
    width: '80px',
    align: 'center',
    title: '浏览人数',
    key: 'look_user_num',
    dataIndex: 'look_user_num',
    scopedSlots: { customRender: 'look_user_num' },
  },
  {
    width: '80px',
    align: 'center',
    title: '浏览次数',
    key: 'look_num',
    dataIndex: 'look_num',
    scopedSlots: { customRender: 'look_num' },
  },
  {
    width: '150px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

const columnsActivity = [
  {
    width: '180px',
    align: 'center',
    title: '话题名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '80px',
    align: 'center',
    title: '问题数量',
    key: 'ask_num',
    dataIndex: 'ask_num',
    scopedSlots: { customRender: 'ask_num' },
  },
  {
    width: '120px',
    align: 'center',
    title: '创建时间',
    dataIndex: 'created_at_1',
    key: 'created_at_1',
    scopedSlots: { customRender: 'created_at_1' },
  },
  {
    width: '80px',
    align: 'center',
    title: '浏览人数',
    key: 'look_user_num',
    dataIndex: 'look_user_num',
    scopedSlots: { customRender: 'look_user_num' },
  },
  {
    width: '80px',
    align: 'center',
    title: '浏览次数',
    key: 'look_num',
    dataIndex: 'look_num',
    scopedSlots: { customRender: 'look_num' },
  },
  {
    width: '80px',
    align: 'center',
    title: '回答数',
    key: 'answer_num',
    dataIndex: 'answer_num',
    scopedSlots: { customRender: 'look_num' },
  },
  {
    width: '80px',
    align: 'center',
    title: '点赞数',
    key: 'praise_num',
    dataIndex: 'praise_num',
    scopedSlots: { customRender: 'look_num' },
  },
  {
    width: '80px',
    align: 'center',
    title: '分享数',
    key: 'share_num',
    dataIndex: 'share_num',
    scopedSlots: { customRender: 'look_num' },
  },
  {
    width: '80px',
    align: 'center',
    title: '关注数',
    key: 'follow_num',
    dataIndex: 'follow_num',
    scopedSlots: { customRender: 'look_num' },
  },
  {
    width: '150px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Topic',
  components: {
    CommonTitle,
    SelectDate,
    AddTopicModel,
    Pagination
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      isActivity,
      columns: isActivity ? columnsActivity : columns,
      loading: false,
      listDefaultShownCount: 2,
      start_day: '',
      end_day: '',
      pageType: '0',
      topicTitle: '',
      topicNum: 0,
      pagination: getPagination(),
      topicId: 0,
      edit_id: 0,
      titles: [{title: '话题管理'}, {title: '话题列表'}],
      data: [],
      selectDate: '' // 查询时间
    }
  },
  created() {
    // this.getData();
  },
  destroyed() {
  },
  methods: {
    handleSearch() {
      this.getData();
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        start_day: this.start_day,
        end_day: this.end_day,
        page: page,
        per_page: pageSize,
      };
      const paramsActivity = {
        start_time: this.start_day,
        end_time: this.end_day,
        page: page,
        per_page: pageSize,
      };
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await (this.isActivity ? getTopicListActivity(paramsActivity) : getTopicList(params)).finally(() => {
        this.loading = false
      });
      if (!data || data.error_code) {
        return;
      }
      this.data = data.data;
      const pagination = data?.meta?.pagination || {};
      this.topicNum = this.data.length || 0;
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    // 创建话题
    addTopic() {
      this.pageType = '0';
      this.$refs.addTopic?.handleShowModal();
    },
    async doSaveTopic(item) {
      item.topic_id = 0;
      const data = await saveTopicDetail(item);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      this.$refs.addTopic?.hideModal();
      await this.getData();
    },
    async doEditTopic(item) {
      item.topic_id = this.edit_id;
      const data = await saveTopicDetail(item);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      this.$refs.addTopic?.hideModal();
      await this.getData();
    },
    handleReset() {
      this.start_day = '';
      this.end_day = '';
      this.$refs.selectDate.getDate();
    },
    // 选择日期
    handleSelectDate(val) {
      this.start_day = val[0] || '';
      this.end_day = val[1] || '';
      this.getData();
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    // 编辑话题
    handleEditTopic({ title, id }) {
      this.pageType = '1';
      this.topicTitle = title;
      this.edit_id = id;
      this.$refs.addTopic?.handleShowModal();
    },
    // // 上架话题
    // handleShelvesTopic() {
    //   this.$confirm({
    //     title: '确定要上架该话题吗？',
    //     onOk() {
    //       this.goShelves();
    //     },
    //     onCancel() {
    //     },
    //     class: 'test',
    //   });
    // },
    // goShelves() {
    // },
    // // 下架话题
    // handleOffTopic() {
    //   this.$confirm({
    //     title: '确定要下架该话题吗？',
    //     onOk() {
    //       this.goOffShelves();
    //     },
    //     onCancel() {
    //     },
    //     class: 'test',
    //   });
    // },
    // goOffShelves() {
    // },
    // 删除话题
    handleDelTopic({ id }) {
      this.topicId = id;
      let _self = this;
      this.$confirm({
        content: '确定要删除该话题吗？',
        onOk() {
          _self.goDel();
        },
        onCancel() {
        },
        class: 'test',
      });
    },
    async goDel() {
      const params = {};
      params.topic_id = this.topicId;
      const data = await deleteTopic(params);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      await this.getData();
    }
  },
}
</script>

<style scoped lang="scss">
.oc-topic {
  .search {
    display: flex;
    align-items: center;
    padding: 3px 15px 5px 30px;
    .select-date {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }

  & .row-wrap {
    flex-wrap: wrap;
  }
}
</style>
