<template>
  <div class="oc-add-answer full-page">
    <CommonTitle :titles="titles"/>

    <div class="ph-20 strong-text">问题：{{ quizName }}</div>

    <a-menu v-model="selectedMenu" mode="horizontal" class="ml-20 mr-30 mb-5">
      <a-menu-item v-for="item of menus" :key="item.id">{{ item.title }}</a-menu-item>
    </a-menu>

    <div class="ph-20 container">
      <div v-show="menu === 1" ref="editorRef" class="oc-editor">
        <editor v-model="editorContent" :init="editorInit"></editor>
      </div>
      <div v-show="menu === 2">
        <UploadVideo v-model="videoInfo"/>
        <a-textarea v-model="videoMsg" placeholder="详细介绍下视频（选填）"
                    :allow-clear="true" :rows="6"></a-textarea>
      </div>
    </div>

    <div class="flex flex-center mt-20">
      <a-button @click="back">取消</a-button>
      <a-button type="primary" @click="save">提交</a-button>
    </div>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import Editor from '@tinymce/tinymce-vue';

import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/lists';
import 'tinymce/icons/default/icons';

import {tinymceOssBaseUrl} from '@/Config';

import {mapState} from 'vuex';
import {uploadFile} from '@/api/Common';
import {getAnswerDetail, getQuizMsg, saveAnswerDetail} from '@/api/onlineCommunity/Index';

import CommonTitle from '@components/CommonTitle';
import UploadVideo from '@components/UploadVideo';


export default {
  name: 'AddAnswerTinyMceVue',
  components: {CommonTitle, Editor, UploadVideo},
  computed: {
    ...mapState({}),
    menu() {
      return this.selectedMenu[0] || 1;
    },
  },
  data() {
    return {
      titles: [{title: '问题列表'}, {title: '添加回答'}],
      loading: false,
      qid: 0,

      quizName: '',

      selectedMenu: [1],
      menus: [
        {id: 1, title: '图文回答'},
        {id: 2, title: '视频回答'},
      ],

      editor: undefined,
      editorContent: '',
      editorInit: {
        menubar: false,
        statusbar: false,
        branding: false,
        toolbar:
          [
            'formatselect | bold italic underline strikethrough | fontsizeselect | forecolor | removeformat' +
            'bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | link unlink image | undo redo | fullscreen',
          ],
        content_css: `${tinymceOssBaseUrl}skins/content/default/content.css`,
        skin_url: `${tinymceOssBaseUrl}skins/ui/oxide`,
        language_url: `${tinymceOssBaseUrl}zh_CN.js`,
        language: 'zh_CN',
        content_style: '.mce-content-body {font-size:14px;}',
        height: 380,
        plugins: 'lists image code fullscreen link',
        fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px',
        charLimit: 100,
        images_upload_handler: this.handleImgUpload,
      },

      videoInfo: {videoUrl: '', coverUrl: ''},
      videoMsg: '',
    };
  },
  created() {
    this.qid = this.$route.query?.qid || 0;

    this.qid && this.getQuizMsg();
    // this.qid && this.getAnswerDetail();
  },
  methods: {
    back() {
      this.$router.back();
    },
    async save() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams();
      const activeEditor = tinymce.activeEditor;
      const editBody = activeEditor.getBody().textContent;
      console.log(editBody);
      if (this.selectedMenu[0] === 1 && !editBody) {
        this.$message.error('请完善图文回答');
        this.loading = false;
        return false;
      }
      if (this.selectedMenu[0] === 2 && !params.cover_url) {
        this.$message.error('请上传视频');
        this.loading = false;
        return false;
      }
      const data = await saveAnswerDetail(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '保存失败');
      }
      this.$message.success(data?.message || '保存成功');
      this.back();
    },
    getParams() {
      const content = this.getEditorContent();
      const params = {ask_id: this.qid};

      if (this.selectedMenu?.length && this.selectedMenu[0] === 2) {
        params.type = 2;
        params.other_json = '["' + (this.videoInfo?.videoUrl || '') + '"]';
        params.cover_url = this.videoInfo?.coverUrl || '';
        this.videoMsg && (params.digest = this.videoMsg);
      } else {
        params.type = 0;
        params.content = content?.html || '';
        params.digest = content?.text || '';
      }

      return params;
    },

    async getQuizMsg() {
      const data = await getQuizMsg(this.qid);
      this.quizName = data?.data?.title || '';
    },
    async getAnswerDetail() {
      this.loading = true;

      const data = await getAnswerDetail(this.qid).finally(() => this.loading = false);
      if (!data || data?.error_code) {
        return;
      }

      const result = data?.data || {};
      if (result.type === 2) {
        this.selectedMenu = [2];
        this.videoUrl = result.other_json?.length ? result.other_json[0] : '';
        this.videoMsg = result?.digest || '';
      } else {
        this.selectedMenu = [1];
        this.setEditorContent(result.content || '');
      }
    },

    handleImgUpload(blobInfo, success, fail) {
      this.uploadPic(blobInfo.blob()).then((url) => success(url)).catch((e) => fail(e));
    },
    async uploadPic(data) {
      const params = new FormData();
      params.append('image', data);
      const response = await uploadFile(params);
      if (!response || response.error_code || !response?.data?.url) {
        this.$message.error(response?.message || '图片上传失败');
        return Promise.reject(response || 'ERROR');
      }
      this.$message.success(response?.message || '图片上传成功');

      return Promise.resolve(response.data.url);
    },

    getEditorContent() {
      return {
        html: this.editorContent,
      };
    },
    setEditorContent(content) {
      this.editorContent = content;
    },
  },
};
</script>

<style scoped lang="scss">
.oc-add-answer {
  .container {
    position: relative;
    z-index: 1001;
  }
}
</style>
