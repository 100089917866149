<template>
  <a-modal title="添加讲师"
           :visible="shown"
           :confirm-loading="confirmLoading"
           :mask-closable="false"
           :width="500"
           :centered="true"
           :destroy-on-close="true"
           wrap-class-name="add-topic-modal"
           @ok="confirm"
           @cancel="close">
    <a-form-model
      ref="topicForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="user_id" label="输入讲师账号ID" prop="user_id">
        <a-input
          v-model="form.user_id"
          placeholder="请输入讲师账号ID"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
  export default {
    name: "AddTopicModel",
    data() {
      return {
        pageType: '0',
        shown: false,
        confirmLoading: false,
        labelCol: { span: 8 },
        wrapperCol: { span: 10 },
        form: {
          user_id: '' // 话题标题
        },
        rules: {
          user_id: [
            { required: true, message: '请填写讲师账号ID', trigger: 'blur' }
          ],
        },
      }
    },
    methods: {
      // 展示弹出框
      handleShowModal() {
        this.shown = true;
      },
      handleSaveModal() {
        this.form.user_id = '';
        this.shown = false;
      },
      // 确认
      confirm() {
        this.$refs.topicForm.validate(valid => {
          if (valid) {
            this.goAddTeacher();
          } else {
            return false;
          }
        });
      },
      // 添加讲师
      goAddTeacher() {
        console.log('新增啦');
        this.$emit('do-save-official', this.form)
      },
      // 取消
      close() {
        this.shown = false;
      }
    }
  }
</script>

<style scoped>

</style>
