<template>
  <div>
    <a-upload
      name="avatar"
      list-type="picture-card"
      :show-upload-list="false"
      :custom-request="customRequest">
      <div v-if="videoUrl" class="flex flex-start">
        <img v-if="coverUrl" :src="coverUrl" alt="视频封面" class="cover-img" @click.stop="previewVideo"/>
        <a-button v-if="videoUrl" class="ml-20" @click.stop="previewVideo">预览视频</a-button>
        <a-button v-if="videoUrl" class="ml-20" @click.stop="removeVideo">删除</a-button>
      </div>
      <div v-else>
        <a-icon :type="videoLoading ? 'loading' : 'plus'"/>
        <div class="ant-upload-text">添加视频</div>
      </div>
    </a-upload>

    <video-viewer ref="videoViewerRef"/>
  </div>
</template>

<script>
import {uploadFile} from '@/api/Common';
import VideoViewer from '@components/VideoViewer';

export default {
  name: 'UploadVideo',
  components: {VideoViewer},
  model: {
    prop: 'videoInfo',
    event: 'changed',
  },
  props: {
    videoInfo: {type: Object, default: () => ({})},
  },
  data() {
    return {
      videoLoading: false,
      videoUrl: '',
      coverUrl: '',
    };
  },
  methods: {
    customRequest({file}) {
      return this.uploadVideo(file);
    },

    async uploadVideo(file) {
      if (this.videoLoading) {return;}
      this.videoLoading = true;

      const formData = new FormData();
      formData.append('video', file);

      const data = await uploadFile(formData).finally(() => this.videoLoading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '视频上传失败');
        return Promise.reject(data || 'ERROR');
      }
      this.$message.success(data?.message || '视频上传成功');

      this.videoUrl = data.data.url || '';
      this.coverUrl = data.data.screentshot || '';
      this.$emit('changed', {videoUrl: this.videoUrl, coverUrl: this.coverUrl});
      this.$emit('uploaded', data);

      return Promise.resolve(data);
    },

    removeVideo() {
      this.videoUrl = '';
      this.coverUrl = '';
      this.fileName = '';
      this.$emit('changed', {videoUrl: this.videoUrl, coverUrl: this.coverUrl});
    },

    previewVideo() {
      this.$refs.videoViewerRef?.show && this.$refs.videoViewerRef.show({url: this.videoUrl});
    },
  },
};
</script>

<style scoped lang="scss">
.cover-img {
  max-height: 240px;
}
</style>
