<template>
  <div class="oc-report full-page">
    <CommonTitle :titles="titles"/>
    <div class="search row-wrap">
      <div class="select-date mb-10">
        <span>投诉人：</span>
        <a-input placeholder="请输入投诉人" v-model="reportName"></a-input>
      </div>
      <div class="select-date mb-10">
        <span>内容关键字：</span>
        <a-input placeholder="请输入内容关键字" v-model="reportKeyword"></a-input>
      </div>
      <div class="select-date mb-10">
        <span>举报类型：</span>
        <a-select style="width: 120px" v-model="reportType" :allowClear="true">
          <a-select-option
            v-for="item in reportTypeList"
            :value="item.id"
            :key="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
      <div class="select-date mb-10">
        <span>状态：</span>
        <a-select style="width: 120px" v-model="reportStatus" :allowClear="true">
          <a-select-option
            v-for="item in dealStatus"
            :value="item.id"
            :key="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
      <div class="search-btn mb-10">
        <a-button type="primary" @click="handleSearch">搜索</a-button>
        <a-button @click="handleReset">重置</a-button>
        <a-button @click="handleExport">导出</a-button>
      </div>
    </div>
    <div class="report-list">
      <a-card
        style="width:100%"
        :tab-list="tabListNoTitle"
        :active-tab-key="noTitleKey"
        @tabChange="key => onTabChange(key)">
        <div
          slot="reportQuestion"
          slot-scope="item">
          <span>{{ item.title }}</span>
          <br/>
          <span class="f12">({{ askNoDealNums }}未处理)</span>
        </div>
        <div
          slot="reportAnswer"
          slot-scope="item">
          <span>{{ item.title }}</span>
          <br/>
          <span class="f12">({{ answerNoDealNums }}未处理)</span>
        </div>
        <div
          slot="reportShare"
          slot-scope="item">
          <span>{{ item.title }}</span>
          <br/>
          <span class="f12">({{ shareNoDealNums }}未处理)</span>
        </div>
        <div v-if="noTitleKey === '1'">
          <a-table
            :columns="columns1"
            :data-source="questionData"
            :pagination="false"
            :loading="questionLoading" bordered
            row-key="id" class="table small-cell-table">
            <template slot="action" slot-scope="text, record">
              <div class="table-cell-wrapper">
                <a-button type="link" size="small" @click="handleCheckDetail(record)">查看详情</a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '1')"
                  v-if="record.report_status === 0">
                  删除处理
                </a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '2')"
                  v-if="record.report_status === 0">
                  不违规
                </a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '3')"
                  v-if="record.report_status === 0">
                  恶意举报
                </a-button>
              </div>
            </template>
            <template slot="footer">
              <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                          class="pagination"
                          @change="onPageChange"
                          @showSizeChange="onSizeChange"></Pagination>
            </template>
          </a-table>
        </div>
        <div v-else-if="noTitleKey === '2'">
          <a-table
            :columns="columns2"
            :data-source="answerData"
            :pagination="false"
            :loading="answerLoading" bordered
            row-key="id" class="table small-cell-table">
            <template slot="action" slot-scope="text, record">
              <div class="table-cell-wrapper">
                <a-button type="link" size="small" @click="handleCheckDetail(record)">查看详情</a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '1')"
                  v-if="record.report_status === 0">
                  删除处理
                </a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '2')"
                  v-if="record.report_status === 0">
                  不违规
                </a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '3')"
                  v-if="record.report_status === 0">
                  恶意举报
                </a-button>
              </div>
            </template>
            <template slot="footer">
              <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                          class="pagination"
                          @change="onPageChange"
                          @showSizeChange="onSizeChange"></Pagination>
            </template>
          </a-table>
        </div>
        <div v-else-if="noTitleKey === '3'">
          <a-table
            :columns="columns3"
            :data-source="shareData"
            :pagination="false"
            :loading="shareLoading" bordered
            row-key="id" class="table small-cell-table">
            <template slot="action" slot-scope="text, record">
              <div class="table-cell-wrapper">
                <a-button type="link" size="small" @click="handleCheckDetail(record)">查看详情</a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '1')"
                  v-if="record.report_status === 0">
                  删除处理
                </a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '2')"
                  v-if="record.report_status === 0">
                  不违规
                </a-button>
                <a-button
                  type="link"
                  size="small"
                  @click="handleDealReport(record, '3')"
                  v-if="record.report_status === 0">
                  恶意举报
                </a-button>
              </div>
            </template>
            <template slot="footer">
              <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                          class="pagination"
                          @change="onPageChange"
                          @showSizeChange="onSizeChange"></Pagination>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import CommonTitle from '@components/CommonTitle';
import Pagination, {getPagination} from '@components/Pagination';
import {
  getReportList,
  getReportListDownload,
  doUpdateReportStatus,
  doGetNoDealNums
} from '@/api/onlineCommunity/Report';
import Common from '@/utils/Common';
import {mapState} from 'vuex';

const columns1 = [
  {
    width: '100px',
    align: 'center',
    title: '问题',
    dataIndex: 'question_title',
    key: 'question_title',
  },
  {
    width: '80px',
    align: 'center',
    title: '题主',
    key: 'custom_user_name',
    dataIndex: 'custom_user_name',
    scopedSlots: {customRender: 'custom_user_name'},
  },
  {
    width: '80px',
    align: 'center',
    title: '举报人',
    dataIndex: 'report_user_name',
    key: 'report_user_name',
    scopedSlots: {customRender: 'report_user_name'},
  },
  {
    width: '120px',
    align: 'center',
    title: '举报类型',
    key: 'report_type_title',
    dataIndex: 'report_type_title',
    scopedSlots: {customRender: 'report_type_title'},
  },
  {
    width: '180px',
    align: 'center',
    title: '描述',
    key: 'content',
    dataIndex: 'content',
    scopedSlots: {customRender: 'content'},
  },
  {
    width: '80px',
    align: 'center',
    title: '状态',
    key: 'report_status_title',
    dataIndex: 'report_status_title',
    scopedSlots: {customRender: 'report_status_title'},
  },
  {
    width: '100px',
    align: 'center',
    title: '处理时间',
    key: 'deal_time_1',
    dataIndex: 'deal_time_1',
    scopedSlots: {customRender: 'deal_time_1'},
  },
  {
    width: '150px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];

const columns2 = [
  {
    width: '180px',
    align: 'center',
    title: '回答',
    dataIndex: 'answer_title',
    key: 'answer_title',
  },
  {
    width: '80px',
    align: 'center',
    title: '答主',
    key: 'custom_user_name',
    dataIndex: 'custom_user_name',
    scopedSlots: {customRender: 'custom_user_name'},
  },
  {
    width: '80px',
    align: 'center',
    title: '举报人',
    dataIndex: 'report_user_name',
    key: 'report_user_name',
    scopedSlots: {customRender: 'report_user_name'},
  },
  {
    width: '80px',
    align: 'center',
    title: '举报类型',
    key: 'report_type_title',
    dataIndex: 'report_type_title',
    scopedSlots: {customRender: 'report_type_title'},
  },
  {
    width: '180px',
    align: 'center',
    title: '描述',
    key: 'content',
    dataIndex: 'content',
    scopedSlots: {customRender: 'content'},
  },
  {
    width: '80px',
    align: 'center',
    title: '状态',
    key: 'report_status_title',
    dataIndex: 'report_status_title',
    scopedSlots: {customRender: 'report_status_title'},
  },
  {
    width: '100px',
    align: 'center',
    title: '处理时间',
    key: 'deal_time_1',
    dataIndex: 'deal_time_1',
    scopedSlots: {customRender: 'deal_time_1'},
  },
  {
    width: '150px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];

const columns3 = [
  {
    width: '180px',
    align: 'center',
    title: '作品标题',
    dataIndex: 'share_title',
    key: 'share_title',
  },
  {
    width: '80px',
    align: 'center',
    title: '发布人',
    key: 'custom_user_name',
    dataIndex: 'custom_user_name',
    scopedSlots: {customRender: 'custom_user_name'},
  },
  {
    width: '80px',
    align: 'center',
    title: '举报人',
    dataIndex: 'report_user_name',
    key: 'report_user_name',
    scopedSlots: {customRender: 'report_user_name'},
  },
  {
    width: '80px',
    align: 'center',
    title: '举报类型',
    key: 'report_type_title',
    dataIndex: 'report_type_title',
    scopedSlots: {customRender: 'report_type_title'},
  },
  {
    width: '180px',
    align: 'center',
    title: '描述',
    key: 'content',
    dataIndex: 'content',
    scopedSlots: {customRender: 'content'},
  },
  {
    width: '80px',
    align: 'center',
    title: '状态',
    key: 'report_status_title',
    dataIndex: 'report_status_title',
    scopedSlots: {customRender: 'report_status_title'},
  },
  {
    width: '100px',
    align: 'center',
    title: '处理时间',
    key: 'deal_time_1',
    dataIndex: 'deal_time_1',
    scopedSlots: {customRender: 'deal_time_1'},
  },
  {
    width: '150px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  name: 'Report',
  components: {
    CommonTitle,
    Pagination
  },
  computed: {
    ...mapState({
      reportTypeList: state => state.Common.reportType,
      dealStatus: state => state.Common.dealStatus
    }),
  },
  data() {
    return {
      titles: [{title: '举报管理'}, {title: '举报列表'}],
      reportName: '', // 投诉人
      reportKeyword: '', // 内容关键字
      columns1,
      columns2,
      columns3,
      noTitleKey: '1',
      questionData: [],
      pagination: getPagination(),
      reportType: '-1', // 举报类型
      reportStatus: '-1',
      questionLoading: false,
      nowId: '', // 当前处理id
      answerData: [],
      shareData: [],
      shareLoading: false,
      downloading: false,
      down: 0,
      answerLoading: false,
      answerNoDealNums: 0,
      askNoDealNums: 0,
      shareNoDealNums: 0,
      tabListNoTitle: [
        {
          key: '1',
          title: '举报问题',
          scopedSlots: {tab: 'reportQuestion'},
        },
        {
          key: '2',
          title: '举报回答',
          scopedSlots: {tab: 'reportAnswer'},
        },
        {
          key: '3',
          title: '举报分享',
          scopedSlots: {tab: 'reportShare'},
        }
      ],
    }
  },
  created() {
    this.$store.dispatch('Common/getReportType');
    this.getDealNum();
    this.getData();
  },
  destroyed() {
  },
  methods: {
    handleSearch() {
      this.down = 0;
      this.getData();
    },
    // 获取未处理数
    async getDealNum() {
      const params = {
        inform_true_name: this.reportName,
        content: this.reportKeyword,
        report_type: this.reportType,
        status: this.reportStatus,
      };
      const data = await doGetNoDealNums(params);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.askNoDealNums = data.data.ask?.num || 0;
      this.answerNoDealNums = data.data.answer?.num || 0;
      this.shareNoDealNums = data.data?.find?.num || 0;
    },
    onTabChange(val) {
      this.noTitleKey = val;
      this.down = 0;
      this.getData();
    },
    handleCheckDetail({id}) {
      console.log('查看详情');
      this.$router.push({
        path: '/index/onlineCommunity/ReportDetail',
        query: {
          id,
        }
      })
    },
    handleDealReport({id}, type) {
      this.nowId = id;
      let _self = this;
      if (type === '1' || type === '3') {
        const keyword = type === '1' ? '删除处理' : '恶意举报';
        console.log(keyword);
        this.$confirm({
          content: `确定要进行${keyword}操作吗？`,
          onOk() {
            _self.doDeal(type);
          },
          onCancel() {
            console.log('Cancel');
          },
          class: 'test',
        });

      } else if (type === '2') {
        this.doDeal(type);
      }
    },
    async doDeal(type) {
      console.log(type);
      const params = {};
      switch (type) {
        case '1':
          params.value = '2';
          break;
        case '2':
          params.value = '1';
          break;
        case '3':
          params.value = '3';
          break;
        default:
          params.value = '';
          break;
      }
      params.id = this.nowId;
      params.field = 'report_status';
      console.log(params);
      const data = await doUpdateReportStatus(params);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      await this.handleSearch();
    },
    handleReset() {
      this.reportName = '';
      this.reportKeyword = '';
      this.reportType = '-1';
      this.reportStatus = '-1';
      this.down = 0;
      this.getData(1, 10);
    },
    async handleExport() {
      this.down = 1;
      await this.onExport();
    },
    async onExport() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = {
        inform_big_type: this.noTitleKey,
        inform_true_name: this.reportName,
        content: this.reportKeyword,
        report_type: this.reportType,
        status: this.reportStatus,
        down: this.down,
        page: this.page,
        per_page: this.per_page,
      };

      const data = await getReportListDownload(params).finally(() => this.downloading = false).catch(() => this.downloading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || `导出失败`);
      }
      this.$message.success(data?.message || '导出成功');
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        inform_big_type: this.noTitleKey,
        inform_true_name: this.reportName,
        content: this.reportKeyword,
        report_type: this.reportType,
        status: this.reportStatus,
        down: this.down,
        page: page,
        per_page: pageSize,
      };
      if (this.noTitleKey === '1') {
        if (this.questionLoading) {
          return;
        }
        this.questionLoading = true;
      } else if (this.noTitleKey === '2') {
        if (this.answerLoading) {
          return;
        }
        this.answerLoading = true;
      }

      const data = await getReportList(params).finally(() => {
        if (this.noTitleKey === '1') {
          this.questionLoading = false;
        } else if (this.noTitleKey === '2') {
          this.answerLoading = false
        }
      });
      if (!data || data.error_code) {
        return;
      }
      data.data.forEach((item) => {
        item.custom_user_name = item.relation_data.created_user_truename;
        item.report_user_name = item.user_data.truename;
        if (this.noTitleKey === '1') {
          item.question_title = item.relation_data.title;
        } else if (this.noTitleKey === '2') {
          item.answer_title = item.relation_data.digest;
        } else if (this.noTitleKey === '3') {
          item.share_title = item.relation_data.title;
        }
      })
      if (this.noTitleKey === '1') {
        this.questionData = data?.data || [];
      } else if (this.noTitleKey === '2') {
        this.answerData = data?.data || [];
      } else if (this.noTitleKey === '3') {
        this.shareData = data?.data || [];
      }
      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    onPageChange(page, pageSize) {
      this.down = 0;
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.down = 0;
      this.getData(1, size);
    },
    hasDeal() {
      console.log('已处理');
    }
  },
}
</script>

<style scoped lang="scss">
.oc-report {
  .search {
    display: flex;
    align-items: center;
    padding: 3px 15px 5px 30px;

    .select-date {
      display: flex;
      align-items: center;
      margin-right: 10px;

      span {
        width: 150px;
        text-align: right;
      }
    }
  }

  & .row-wrap {
    flex-wrap: wrap;
  }

  .report-list {
    margin-top: 20px;
  }

  .f12 {
    font-size: 14px;
    display: block;
    text-align: center;
  }
}
</style>
