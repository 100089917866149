<template>
  <div v-if="shown" class="video-viewer" @click="close">
    <div class="video-box">
      <video :src="url" class="video" :autoplay="false" :controls="true" @click.stop=""></video>
    </div>

    <span class="text">点击任意处关闭</span>
  </div>
</template>

<script>
export default {
  name: 'VideoViewer',
  data() {
    return {
      shown: false,
      url: '',
    };
  },
  methods: {
    show({url}) {
      this.url = url || '';

      this.shown = true;
    },
    close() {
      this.shown = false;
      this.url = '';
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.video-viewer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.2);

  .video-box {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 80%;
    text-align: center;
  }

  .video {
    flex: none;
    max-width: 100%;
    max-height: 100%;
  }

  .text {
    margin-top: 6px;
    padding: 6px 12px;
    border-radius: 2px;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.35);
  }
}
</style>
