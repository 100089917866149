<template>
  <a-modal :title="title"
           :visible="shown"
           :mask-closable="true"
           :width="600"
           :centered="true"
           :closable="true"
           :destroy-on-close="true"
           :after-close="reset"
           @ok="save"
           @cancel="close">
    <div class="flex flex-start strong-text">当前标签</div>
    <div class="flex flex-start flex-wrap mt-10 pb-10 slt-tags">
      <span v-if="!selectedTags || !selectedTags.length">未选中任何标签</span>
      <a-tag v-for="item of selectedTags" :key="item.id" :closable="true" @close="removeTag(item)">
        {{ item.title }}
      </a-tag>
    </div>

    <div v-for="item of tagList" :key="item.id" class="tags mt-10">
      <div class="tags-title mb-5">{{ item.title }}</div>

      <div v-if="item.labels && item.labels.length" class="tag-group">
        <a-checkbox v-for="tag of item.labels"
                    v-model="tag._checked_" :key="tag.id"
                    @change="onCheckChange($event, item, tag)">
          {{ tag.title }}
        </a-checkbox>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {mapState} from 'vuex';
import {setQuizTags} from '@/api/onlineCommunity/Index';

export default {
  name: 'SelectTagsModal',
  props: {
    title: {type: String, default: ''},
  },
  computed: {
    ...mapState({
      tagList: state => state.OnlineCommunity.tagListForSelect,
    }),
  },
  watch: {
    tagList() {
      this.initSelectedTags();
    },
  },
  data() {
    return {
      shown: false,
      confirmLoading: false,
      qid: 0,

      defaultIds: [],
      selectedTags: [],
    };
  },
  created() {
    // this.$store.dispatch('OnlineCommunity/getTagList');
  },
  methods: {
    show({qid, tagIds}) {
      this.qid = qid;
      this.defaultIds = tagIds;

      this.tagList?.length && this.initSelectedTags();
      this.shown = true;
    },
    close() {
      this.shown = false;
    },

    reset() {
      this.confirmLoading = false;
      this.defaultIds = [];
      this.selectedTags = [];
      this.clearTagChecked();
    },

    save() {
      if (!this.qid || this.qid <= 0) {
        this.emitSave();
        this.close();
      } else {
        this.setQuizTags().then(() => {
          this.emitSave();
          this.close();
        });
      }
    },
    emitSave() {
      const tagIds = this.selectedTags.map(i => i.id);
      this.$emit('save', {
        qid: this.qid,
        tags: this.selectedTags,
        tagIds,
        tagIdsStr: tagIds.join(','),
        tagNameStr: this.selectedTags.map(i => i.title).join(','),
      });
    },

    async setQuizTags() {
      // if (!this.selectedTags?.length) {return this.$message.info('请选择标签');}
      if (this.selectedTags?.length > 5) {
        this.$message.info('最多只能添加5个标签');
        return Promise.reject('WARNING: MAX');
      }
      if (this.confirmLoading) {return Promise.reject('WARNING: LOADING');}
      this.confirmLoading = true;

      const data = await setQuizTags(this.qid, {label_ids: this.selectedTags.map(i => i.id).join(',')});
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return Promise.reject(data || 'ERROR');
      }
      this.$message.success(data?.message || '保存成功');
      return Promise.resolve(data);
    },

    initSelectedTags() {
      const list = this.tagList || [];

      if (this.defaultIds) {
        const selectedTags = [];
        list?.length && list.forEach(tag => {
          tag?.labels?.length && tag.labels.forEach(i => {
            if (this.defaultIds.includes(i.id)) {
              selectedTags.push(i);
            }
          });
        });
        this.selectedTags = selectedTags;
        this.updateTagList({addCheckedIds: selectedTags.map(i => i.id)});
        this.defaultIds = undefined;
      }
    },

    onCheckChange(ev, item, tag) {
      if (ev.target?.checked) {
        this.addTag(tag);
      } else {
        this.removeTag(tag);
      }
    },

    removeTag(tag) {
      const tags = [...this.selectedTags];
      const idx = tags.findIndex(i => i.id === tag.id);
      if (idx === -1) {
        return;
      }

      this.updateTagList({removeCheckedIds: [tags[idx].id]});

      tags.splice(idx, 1);
      this.selectedTags = tags;
    },
    addTag(tag) {
      const tags = [...this.selectedTags];
      const idx = tags.findIndex(i => i.id === tag.id);
      if (idx > -1) {
        return;
      }
      tag._checked_ = true;
      tags.push(tag);
      this.selectedTags = tags;
    },

    updateTagList({addCheckedIds = [], removeCheckedIds = []}) {
      const tagList = [...this.tagList];
      tagList.forEach(tag => {
        if (tag?.labels?.length) {
          tag.labels.forEach(i => {
            this.updateTagChecked(i, {addCheckedIds, removeCheckedIds});
          });
        }
      });

      this.$store.commit('OnlineCommunity/updateTagListForSelect', tagList);
    },
    updateTagChecked(tag, {addCheckedIds = [], removeCheckedIds = []}) {
      if (!tag) {return;}

      if (addCheckedIds.includes(tag.id)) {
        tag._checked_ = true;
      }
      if (removeCheckedIds.includes(tag.id)) {
        tag._checked_ = false;
      }
    },
    clearTagChecked() {
      const tagList = [...this.tagList];
      tagList.forEach(tag => {
        if (tag?.labels?.length) {
          tag.labels.forEach(i => i._checked_ = false);
        }
      });

      this.$store.commit('OnlineCommunity/updateTagListForSelect', tagList);
    },
  },
};
</script>

<style scoped lang="scss">
.slt-tags {
  min-height: 36px;
  border-bottom: 1px solid #C1C1C1;
}
</style>
