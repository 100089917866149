<template>
  <answer/>
</template>

<script>
import Answer from './Answer';

export default {
  name: 'AnswerList',
  components: { Answer },
};
</script>
