<template>
  <div class="select-date">
    <a-range-picker
      v-model="dateRange"
      :disabled-date="disabledDate"
      @change="onChange">
    </a-range-picker>
  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: "DatePicker",
    data() {
      this.dateFormat = 'YYYY-MM-DD';
      return {
        startDate: '',
        endDate: '',
        nowYear: '',
        nowMonth: '',
        dateRange: [],
        nowDay: '',
        ranges: []
      }
    },
    created() {
      this.getDate();
    },
    methods: {
      moment,
      disabledDate (current) {
        return current < moment().subtract(31, 'days') || current > moment().endOf('day')
      },
      //  选择日期
      onChange(date, dateString) {
        console.log(date, dateString);
        this.$emit('select-date', dateString);
      },
      getDate() {
        const start = moment().subtract(30, 'days');
        const end = moment();
        this.dateRange = [start, end];
        const formatDate = [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]
        this.$emit('select-date', formatDate);
      }
    }
  }
</script>

<style scoped>

</style>
