<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'OnlineCommunity',
  created() {
  },
};
</script>

<style scoped lang="scss">

</style>
