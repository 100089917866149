<template>
  <a-input-group compact class="count-filter">
    <a-select v-model="filter" class="cf-select" @change="changed">
      <a-select-option v-for="(item) in filters" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
    </a-select>
    <a-input-number v-model="count" :min="0" :max="9999" class="cf-input" @change="changed"/>
  </a-input-group>
</template>

<script>
const defaultFilter = 'eq';
const defaultCount = undefined;

export default {
  name: 'CountFilter',
  model: {
    prop: 'data',
    event: 'changed',
  },
  props: {
    data: {type: Object, default: () => ({})},
  },
  watch: {
    'data.filter'(val, oldVal) {
      if (val !== oldVal) {this.filter = val;}
    },
    'data.count'(val, oldVal) {
      if (val !== oldVal) {this.count = val;}
    },
  },
  data() {
    return {
      filters: [
        {value: 'eq', name: '等于'},
        {value: 'gt', name: '大于'},
        {value: 'lt', name: '小于'},
      ],

      filter: defaultFilter,
      count: defaultCount,
    };
  },
  created() {
    this.filter = this.data?.filter || defaultFilter;
    this.count = this.data?.count || defaultCount;
  },
  methods: {
    changed() {
      this.$emit('changed', {filter: this.filter, count: this.count});
    },
  },
};
</script>

<style scoped lang="scss">
.count-filter {
  .cf-select {
    width: 80px;
  }

  .cf-input {
    width: 100px;
  }
}
</style>
