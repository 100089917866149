<template>
  <a-modal :title="pageType === '0'? '创建话题' : '编辑话题'"
           :visible="shown"
           :confirm-loading="confirmLoading"
           :mask-closable="false"
           :width="600"
           :centered="true"
           :destroy-on-close="true"
           wrap-class-name="add-topic-modal"
           @ok="confirm"
           @cancel="close">
    <a-form-model
      ref="topicForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="title" label="话题名称" prop="title">
        <a-input
          v-model="form.title"
          placeholder="请输入话题名称"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
  export default {
    name: "AddTopicModel",
    props: {
      pageType: {
        type: String,
        default() {
          return ''
        }
      },
      topicTitle: {
        type: String,
        default() {
          return ''
        }
      }
    },
    watch: {
      topicTitle: {
        handler(newVal, oldVal) {
          if (oldVal !== newVal) {
            this.form.title = newVal;
          }
        },
        immediate: true
      }
    },
    data() {
      return {
        shown: false,
        confirmLoading: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 10 },
        form: {
          title: '' // 话题标题
        },
        rules: {
          title: [
            { required: true, message: '请填写话题名称', trigger: 'blur' },
            { min: 1, max: 10, message: '话题名称长度在 1 到 10 之间', trigger: 'blur' },
          ],
        },
      }
    },
    methods: {
      // 展示弹出框
      handleShowModal() {
        this.shown = true;
      },
      hideModal() {
        this.form.title = '';
        this.shown = false;
      },
      // 确认
      confirm() {
        this.$refs.topicForm.validate(valid => {
          if (valid) {
            if (this.pageType === '0') {
              this.goAddTopic();
            } else if (this.pageType === '1') {
              this.goEditTopic();
            }
          } else {
            return false;
          }
        });
      },
      // 添加话题
      goAddTopic() {
        console.log('新增啦');
        this.$emit('add-topic', this.form);
      },
      // 修改话题
      goEditTopic() {
        console.log('修改啦');
        this.$emit('edit-topic', this.form);
      },
      // 取消
      close() {
        this.shown = false;
      }
    }
  }
</script>

<style scoped>

</style>
