<template>
  <div class="oc-answer full-page">
    <CommonTitle :titles="titles" :all-num="answerNum"/>
    <AnswerQuery
      :loading="loading"
      :downloading="downloading"
      :show-cancel="!!qid"
      class="mb-20"
      @on-search="onSearch" @reset="onSearch" @on-export="onExport"/>

    <div class="flex space-between ph-20 mb-20">
      <div class="block strong-text">
        <template v-if="qid">
          <span>问题：{{ quizName }}</span>
          <span v-if="isScore" class="ml-20">悬赏：{{ score }}分</span>
          <span v-else class="ml-20">悬赏：无</span>
        </template>
      </div>

      <a-button type="primary" class="flex-none" @click="approve">审核通过</a-button>
    </div>


    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             :bordered="false"
             :scroll="{x: '100%'}"
             :row-selection="rowSelection"
             row-key="id"
             class="table small-cell-table ph-20"
             @change="handleChange">
      <div slot="ask" slot-scope="text, record" class="pointer-cursor" @click="viewQuiz(record)">
        {{ record.ask_title }}
      </div>

      <template slot="created_at" slot-scope="text">{{ formatDateTime(text) }}</template>
      <template slot="top_at" slot-scope="text">{{ text ? '是' : '否' }}</template>
      <!--      <template slot="is_accepted" slot-scope="text">{{ text ? '已采纳' : '未采纳' }}</template>-->

      <template slot="action" slot-scope="text, record">
        <a-button v-if="record.top_at" type="link" @click="setTop(record)">取消精华</a-button>
        <a-button v-else type="link" @click="setTop(record)">精华</a-button>

        <a-button v-if="record.is_pass === 1" type="link" @click="resetPass(record)">重置审核</a-button>
        <a-button v-else type="link" @click="setPass(record)">审核通过</a-button>

        <a-button type="link" @click="viewUserInfo(record)">答主信息</a-button>
        <a-button type="link" @click="viewComment(record)">查看评论</a-button>
        <a-button type="link" @click="deleteRecord(record)">删除</a-button>
      </template>

      <template slot="footer">
        <Pagination
          v-if="pagination && pagination.total"
          :pagination="pagination"
          class="pagination"
          @change="onPageChange"
          @showSizeChange="onSizeChange"/>
      </template>
    </a-table>

    <UserInfoModal ref="userInfoModalRef" title="答主信息"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import table from '@/mixins/table';

import Common from '@/utils/Common';
import {
  deleteAnswer,
  getAnswerList,
  getAnswerListDownload,
  getAnswerAllList,
  getAnswerAllListDownload,
  getQuizMsg,
  setAnswerTop,
  setAnswerPass,
  resetAnswerPass,
  setBatchAnswerPass,
} from '@/api/onlineCommunity/Index';
import { quizSubPage } from '@/utils/Navs/OnlineCommunity';

import AnswerQuery from '@views/onlineCommunity/components/AnswerQuery';
import UserInfoModal from '@views/onlineCommunity/components/UserInfoModal';
import CommonTitle from '@components/CommonTitle';

const columns = [
  {
    width: '80px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '200px',
    autoExpand: true,
    align: 'center',
    dataIndex: 'digest',
    key: 'digest',
    title: '回答',
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'ask',
    key: 'ask',
    title: '问题',
    scopedSlots: { customRender: 'ask' },
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'created_at',
    key: 'created_at',
    title: '发布时间',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'updated_at_1',
    key: 'updated_at_1',
    title: '更新时间',
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'comment_num',
    key: 'comment_num',
    title: '评论条数',
    sorter: true,
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'comment_user_num',
    key: 'comment_user_num',
    title: '评论人数',
    sorter: true,
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'look_num',
    key: 'look_num',
    title: '浏览量',
    sorter: true,
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'look_user_num',
    key: 'look_user_num',
    title: '浏览人数',
    sorter: true,
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'good_num',
    key: 'good_num',
    title: '点赞数',
    sorter: true,
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'top_at',
    key: 'top_at',
    title: '是否精华',
    // sorter: true,
    scopedSlots: { customRender: 'top_at' },
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'is_accepted_str',
    key: 'is_accepted_str',
    title: '是否采纳',
    // scopedSlots: {customRender: 'is_accepted'},
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'is_pass_str',
    key: 'is_pass_str',
    title: '审核状态',
  },
  {
    width: '100px',
    fixed: 'right',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];


export default {
  name: 'Answer',
  mixins: [table],
  components: { AnswerQuery, UserInfoModal, CommonTitle },
  computed: {
    ...mapState({
      query: state => state.OnlineCommunity.answerQuery,
    }),
    titles() {
      return this.qid ? [{ title: '问题列表' }, { title: '回答列表' }] : [{ title: '回答列表' }];
    },
    columns() {
      return this.originColumns.filter(i => !this.qid || (i.key !== 'ask' && i.key !== 'updated_at_1'));
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
        getCheckboxProps: record => ({
          props: {
            disabled: !!record.is_pass,
          },
        }),
      };
    },
  },
  data() {
    return {
      qid: 0,

      quizName: '',
      score: 0,
      isScore: false,
      answerNum: 0,

      originColumns: columns,

      selectedRowKeys: [],
      selectedRows: [],

      sorter: '',
      sortType: '',
    };
  },
  created() {
    this.$store.commit('OnlineCommunity/resetAnswerQuery');

    this.qid = this.$route.query?.qid || 0;
    this.qid && this.getQuizMsg();
    this.onSearch();
  },
  methods: {
    formatDateTime(time) {
      if (!time) {return '--';}
      return Common.formatDateTime(time * 1000, true);
    },
    handleChange(pagination, filters, sorter) {
      this.sortType = '';
      switch (sorter.order) {
        case 'ascend':
          this.sortType = 'desc';
          break;
        case 'descend':
          this.sortType = 'asc';
          break;
      }
      this.sorter = this.sortType ? sorter.columnKey : '';

      this.onSearch();
    },

    async onExport() {
      if (this.downloading) {return;}
      this.downloading = true;

      const params = this.getParams(this.pagination.current, this.pagination.pageSize);
      params.down = 1;

      const data = await (
        this.qid
          ? getAnswerListDownload(this.qid, params)
          : getAnswerAllListDownload(params)
      ).finally(() => this.downloading = false).catch(() => this.downloading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || `导出失败`);
      }
      this.$message.success(data?.message || '导出成功');
    },

    async getQuizMsg() {
      const data = await getQuizMsg(this.qid);
      this.quizName = data?.data?.title || '';
      this.isScore = data?.data?.is_score || false;
      this.score = data?.data?.score || 0;
    },

    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (this.data?.length - 1 <= 0 && this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
        this.getData(this.pagination.current > 1 ? (this.pagination.current - 1) : 1, this.pagination.pageSize);
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (this.loading) { return; }
      this.loading = true;

      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = this.getParams(page, pageSize);

      const data = await (
        this.qid
          ? getAnswerList(this.qid, params)
          : getAnswerAllList(params)
      ).finally(() => this.loading = false);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '获取列表失败');
      }
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.answerNum = pagination.total || 0;
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize) {
      const params = { page: page, per_page: pageSize };
      this.query?.answerName && (params.content = this.query.answerName);
      this.query?.range && this.query.range[0] && (params.start_time = this.query.range[0].format('YYYY-MM-DD'));
      this.query?.range && this.query.range[1] && (params.end_time = this.query.range[1].format('YYYY-MM-DD'));
      this.query?.answerUserName && (params.user_name = this.query.answerUserName);
      (this.query?.commentCount || this.query?.commentCount === 0) && (params.good_num = this.query.commentCount);
      this.query?.commentFilter && (params.good_type = this.query.commentFilter);
      this.sorter && (params.sort = this.sorter);
      this.sortType && (params.sort_type = this.sortType);

      if (this.query?.auditingStatus === 1) {
        params.is_pass = 0;
      } else if (this.query?.auditingStatus === 2) {
        params.is_pass = 1;
      } else {
        params.is_pass = -1;
      }

      if (this.query?.acceptStatus === 1) {
        params.is_accepted = 0;
      } else if (this.query?.acceptStatus === 2) {
        params.is_accepted = 1;
      } else {
        params.is_accepted = -1;
      }

      return params;
    },

    deleteRecord(record) {
      this.$confirm({
        content: () => <div>确认删除该回答？</div>,
        onOk: () => this.confirmDeleteRecord(record),
      });
    },
    async confirmDeleteRecord(record) {
      const data = await deleteAnswer(record.id);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '删除回答失败');
      }
      this.$message.success(data?.message || '删除成功');
      this.onRefreshPage();
    },

    async setTop(record) {
      if (this.topSetting || this.loading) {return;}
      this.topSetting = true;

      const data = await setAnswerTop(record.id).finally(() => this.topSetting = false);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || `${record.top_at ? '取消' : '设置'}精华失败`);
      }
      this.onRefreshPage();
    },

    async setPass(record) {
      if (this.passSetting || this.loading) {return;}
      this.passSetting = true;

      const data = await setAnswerPass(record.id).finally(() => this.passSetting = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '审核失败');
      }
      this.$message.success(data?.message || '审核成功');
      this.onRefreshPage();
    },
    resetPass(record) {
      this.$confirm({
        content: () => <div>确认重置该审核？</div>,
        onOk: () => this.confirmResetPass(record),
      });
    },
    async confirmResetPass(record) {
      const data = await resetAnswerPass(record.id);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '重置审核失败');
      }
      this.$message.success(data?.message || '重置审核成功');
      this.onRefreshPage();
    },

    viewUserInfo(record) {
      this.$refs?.userInfoModalRef?.show && this.$refs.userInfoModalRef.show({ userId: record.created_user_id });
    },

    viewComment(record) {
      const path = quizSubPage && quizSubPage[1]?.path || '';
      this.$router.push({ path, query: { qid: this.qid, aid: record.id } });
    },

    viewQuiz(record) {
      const path = quizSubPage && quizSubPage[0]?.path || '';
      this.$router.push({ path, query: { qid: record.ask_id || 1 } });
    },

    /**
     * approve multiple answers
     */
    approve() {
      if (!this.selectedRowKeys?.length) {
        this.$message.info('请选择要审核的回答');
        return;
      }
      this.$confirm({
        content: () => <div>确认审核选中的回答？</div>,
        onOk: () => this.confirmApprove(),
      });
    },
    async confirmApprove() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await setBatchAnswerPass({
        answer_ids: this.selectedRowKeys?.join(',') || '',
        is_pass: 1,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '审核失败');
        return;
      }
      this.$message.success(data?.message || '审核成功');
      this.onRefreshPage();
    },
  },
};
</script>

<style scoped lang="scss">
.oc-answer {
}
</style>
