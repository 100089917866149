<template>
  <a-select v-model="filter" class="tristate-selection" @change="changed">
    <a-select-option v-for="(item) in filters" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
  </a-select>
</template>

<script>
const filters = [
  {value: 0, name: '不限'},
  {value: 2, name: '已审核'},
  {value: 1, name: '未审核'},
];
const defaultData = 0;

export default {
  name: 'TristateSelection',
  model: {
    prop: 'data',
    event: 'changed',
  },
  props: {
    data: {type: Number, default: defaultData},
    op0Name: {type: String, default: '不限'},
    op1Name: {type: String, default: '已审核'},
    op2Name: {type: String, default: '未审核'},
  },
  watch: {
    data(ids) {
      this.filter = ids;
    },
  },
  data() {
    return {
      filters: [],
      filter: defaultData,
    };
  },
  created() {
    this.filters = filters.map((i, idx) => {
      i.name = this[`op${idx}Name`] || i.name;
      return {...i};
    });
    this.filter = this.data || defaultData;
  },
  methods: {
    changed() {
      this.$emit('changed', this.filter);
    },
  },
};
</script>

<style scoped lang="scss">
.tristate-selection {
  width: 150px;
}
</style>
