<template>
  <a-form :form="form" layout="inline" class="ph-20 mb-10 answer-query">
    <a-form-item label="回答">
      <a-input v-model="answerName" placeholder="请输入回答内容" :allow-clear="true" class="input"></a-input>
    </a-form-item>

    <a-form-item label="点赞数">
      <CountFilter v-model="countFilter"/>
    </a-form-item>

    <a-form-item label="答主姓名">
      <a-input v-model="answerUserName" placeholder="请输入题主姓名" :allow-clear="true" class="input"></a-input>
    </a-form-item>

    <a-form-item label="发布时间">
      <a-range-picker v-model="range" :allow-clear="true"></a-range-picker>
    </a-form-item>

    <a-form-item label="采纳状态">
      <tristate-selection v-model="acceptStatus" op1-name="是" op2Name="否"/>
    </a-form-item>

    <a-form-item label="审核状态">
      <tristate-selection v-model="auditingStatus"/>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" @click="onSearch">查询</a-button>
    </a-form-item>

    <a-form-item>
      <a-button @click="reset">重置</a-button>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" @click="exportExcel">导出</a-button>
    </a-form-item>

    <a-form-item v-if="showCancel">
      <a-button @click="back">返回</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import CountFilter from '@components/onlineCommunity/CountFilter';
import TristateSelection from '@components/onlineCommunity/TristateSelection';

export default {
  name: 'AnswerQuery',
  components: {CountFilter, TristateSelection},
  props: {
    loading: {type: Boolean, default: false},
    downloading: {type: Boolean, default: false},
    showCancel: {type: Boolean, default: true},
  },
  data() {
    return {
      form: this.$form.createForm(this, {name: 'coordinated'}),

      answerName: '',
      countFilter: undefined,
      range: undefined,
      answerUserName: '',
      acceptStatus: 0,
      auditingStatus: 0,
    };
  },
  created() {
    // this.getQuery();
  },
  methods: {
    getQuery() {
      const query = this.$store.state.OnlineCommunity?.answerQuery || {};

      this.answerName = query.answerName || '';
      this.countFilter = {filter: query.commentFilter, count: query.commentCount};
      this.range = query.range || [];
      this.answerUserName = query.answerUserName || '';
      this.acceptStatus = query.acceptStatus || 0;
      this.auditingStatus = query.auditingStatus || 0;
    },

    saveQuery() {
      this.$store.commit('OnlineCommunity/updateAnswerQuery', {
        answerName: this.answerName || '',
        commentFilter: this.countFilter?.filter,
        commentCount: this.countFilter?.count,
        answerUserName: this.answerUserName || '',
        range: this.range,
        acceptStatus: this.acceptStatus || 0,
        auditingStatus: this.auditingStatus || 0,
      });
    },

    onSearch() {
      this.saveQuery();
      this.$emit('on-search');
    },
    reset() {
      this.$store.commit('OnlineCommunity/resetAnswerQuery');
      this.getQuery();
      this.$emit('reset');
    },
    exportExcel() {
      this.saveQuery();
      this.$emit('on-export');
    },

    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.answer-query {
}
</style>
