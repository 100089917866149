<template>
  <a-modal title="添加封禁"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :centered="true"
           :closable="true"
           :destroy-on-close="true"
           @ok="addUserBan"
           @cancel="close">
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" class="ph-20 mb-10">
      <a-form-item label="输入用户ID">
        <a-input v-model="userId" placeholder="输入用户ID"></a-input>
      </a-form-item>

      <a-form-item label="封禁天数(整数)">
        <a-input-number v-model="days" :min="0" :max="9999" placeholder="封禁天数"
                        :parser="value => value.replace(/\..*/g, '')"></a-input-number>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {addUserBan} from '@/api/onlineCommunity/Index';

export default {
  name: 'BanUserModal',
  data() {
    return {
      loading: false,
      shown: false,
      form: this.$form.createForm(this, {name: 'coordinated'}),

      userId: '',
      days: 0,
    };
  },
  methods: {
    show({userId} = {}) {
      this.userId = userId || '';

      this.shown = true;
    },
    close({success}) {
      this.shown = false;

      success && this.$emit('baned', {userId: this.userId, days: this.days});

      this.userId = '';
      this.days = 0;
    },

    async addUserBan() {
      if (!this.userId) {return this.$message.info('请输入用户 ID');}
      if (!this.days) {return this.$message.info('请输入封禁时长');}

      if (this.loading) {return;}
      this.loading = true;

      const params = {
        user_id: this.userId,
        ban_day: this.days,
      };

      const data = await addUserBan(params).finally(() => this.loading = false);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '添加封禁失败');
      }
      this.$message.success(data?.message || '添加封禁成功');

      this.close({success: true});
    },
  },
};
</script>

<style scoped lang="scss">

</style>
