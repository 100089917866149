<template>
  <div class="oc-ban full-page">
    <CommonTitle :titles="titles">
      <div slot="buttons">
        <a-button @click="showAddBanModal">添加封禁</a-button>
      </div>
    </CommonTitle>

    <div class="flex flex-start ph-20 mb-20 small-text">封禁后，学员无法在社区发布任何内容（问答、评论、回复、作品分享）</div>

    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" :bordered="false"
             row-key="id" class="table small-cell-table ph-20">
      <template slot="ban_at" slot-scope="text">{{ formatDateTime(text) }}</template>

      <template slot="action" slot-scope="text, record">
        <a-button v-if="!record.status" type="link" @click="resetBan(record)">解封</a-button>
      </template>

      <template slot="footer">
        <Pagination
          v-if="pagination && pagination.total"
          :pagination="pagination"
          class="pagination"
          @change="onPageChange"
          @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>

    <BanUserModal ref="banUserModalRef" @baned="onRefreshPage"/>
  </div>
</template>

<script>
import Common from '@/utils/Common';
import {getBanList, removeBanUser} from '@/api/onlineCommunity/Index';

import BanUserModal from '@views/onlineCommunity/components/BanUserModal';
import CommonTitle from '@components/CommonTitle';
import Pagination, {getPagination} from '@components/Pagination';

const columns = [
  {
    width: '80px',
    align: 'center',
    dataIndex: 'user_id',
    key: 'user_id',
    title: 'UID',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'truename',
    key: 'truename',
    title: '姓名',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'mobile',
    key: 'mobile',
    title: '电话',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'ban_at',
    key: 'ban_at',
    title: '封禁开始时间',
    scopedSlots: {customRender: 'ban_at'},
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'continue_day',
    key: 'continue_day',
    title: '封禁持续时间',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'status_str',
    key: 'status_str',
    title: '状态',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  name: 'Ban',
  components: {BanUserModal, CommonTitle, Pagination},
  data() {
    return {
      titles: [{title: '讲师列表'}],
      loading: false,

      columns: columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    formatDateTime(time) {
      if (!time) {return '--';}
      return Common.formatDateTime(time * 1000, true);
    },

    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshPage() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (this.loading) { return; }
      this.loading = true;

      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {page, per_page: pageSize};

      const data = await getBanList(params).finally(() => this.loading = false);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '获取列表失败');
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    showAddBanModal() {
      this.$refs?.banUserModalRef?.show && this.$refs.banUserModalRef.show();
    },

    resetBan(record) {
      this.$confirm({
        content: () => <div>确认解封该用户？</div>,
        onOk: () => this.confirmResetBan(record),
      });
    },
    async confirmResetBan(record) {
      if (this.loading) {return;}
      this.loading = true;

      const data = await removeBanUser({user_id: record.user_id}).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '解封失败');
      }
      this.$message.success(data?.message || '解封成功');
      this.onRefreshPage();
    },

  },
};
</script>

<style scoped lang="scss">
.small-text {
  font-size: 12px;
}
</style>
