<template>
  <div class="oc-quiz full-page">
    <CommonTitle :titles="titles" :all-num="questionNum"/>
    <QuizQuery
      ref="quizQuery"
      @on-search="onSearch"
      @reset="onSearch"
      @on-export="onExport"/>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             :bordered="false"
             :locale="locale"
             :title="() => ''"
             :scroll="{x: '100%'}"
             row-key="id"
             class="table small-cell-table ph-20"
             @change="handleChange">
      <template slot="title" slot-scope="text, record">
        <div @click="showQuizDetail(record)" class="pointer-cursor">
          {{ text.length > 8 ? text.substr(0, 8) + '...' : text }}
        </div>
      </template>
      <template slot="created_at" slot-scope="text">{{ formatDateTime(text) }}</template>

      <template slot="action" slot-scope="text, record">
        <a-button size="small" v-if="record.top_at" type="link" @click="setTop(record)">取消置顶</a-button>
        <a-button size="small" v-else type="link" @click="setTop(record)">置顶</a-button>
        <a-button size="small" type="link" @click="viewUserInfo(record)">题主信息</a-button>
        <a-button size="small" type="link" @click="viewAnswer(record)">查看回答</a-button>
        <a-button size="small" v-if="record.review_status === 1" type="link" @click="resetAuditing(record)">重置审核
        </a-button>
        <a-button size="small" v-else type="link" @click="setAuditing(record)">审核通过</a-button>
        <a-button size="small" type="link" @click="deleteRecord(record)">删除</a-button>
        <a-button size="small" type="link" @click="setAnswer(record)">添加回答</a-button>
        <a-button size="small" type="link" @click="setTag(record)">管理标签</a-button>
      </template>

      <template slot="footer">
        <Pagination
          v-if="pagination && pagination.total"
          :pagination="pagination"
          class="pagination"
          @change="onPageChange"
          @showSizeChange="onSizeChange"/>
      </template>

    </a-table>

    <UserInfoModal ref="userInfoModalRef" title="题主信息"/>
    <SelectTagsModal ref="selectTagsModalRef" title="管理标签" @save="onRefreshPage"/>
    <QuizDetailModal ref="quizDetailModalRef"/>
  </div>
</template>

<script>
// import * as moment from 'moment';
import { mapState } from 'vuex';

import table from '@/mixins/table';

import {
  deleteQuiz,
  getQuizListDownload,
  getQuizList,
  resetQuizAuditing,
  setQuizAuditing,
  setQuizTop,
} from '@/api/onlineCommunity/Index';
import Common from '@/utils/Common';
import { quizSubPage } from '@/utils/Navs/OnlineCommunity';

import CommonTitle from '@components/CommonTitle';
import QuizDetailModal from '@views/onlineCommunity/components/QuizDetailModal';
import QuizQuery from '@views/onlineCommunity/components/QuizQuery';
import SelectTagsModal from '@views/onlineCommunity/components/SelectTagsModal';
import UserInfoModal from '@views/onlineCommunity/components/UserInfoModal';

const columns = [
  {
    width: '50px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '问题',
    scopedSlots: { customRender: 'title' },
    autoExpand: true,
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'topics_txt',
    key: 'topics_txt',
    title: '话题',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'labels_txt',
    key: 'labels_txt',
    title: '标签',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'created_at',
    key: 'created_at',
    title: '发布时间',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'answer_num',
    key: 'answer_num',
    title: '回答条数',
    sorter: true,
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'answer_user_num',
    key: 'answer_user_num',
    title: '回答人数',
    sorter: true,
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'look_num',
    key: 'look_num',
    title: '浏览量',
    sorter: true,
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'look_user_num',
    key: 'look_user_num',
    title: '浏览人数',
    sorter: true,
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'good_num',
    key: 'good_num',
    title: '点赞数',
    sorter: true,
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'share_num',
    key: 'share_num',
    title: '分享次数',
    sorter: true,
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'share_user_num',
    key: 'share_user_num',
    title: '分享人数',
    sorter: true,
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'follow_num',
    key: 'follow_num',
    title: '关注人数',
    sorter: true,
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'review_status_txt',
    key: 'review_status_txt',
    title: '审核状态',
    scopedSlots: { customRender: 'review_status_txt' },
  },
  {
    width: '260px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Quiz',
  mixins: [table],
  components: {
    CommonTitle,
    QuizDetailModal,
    QuizQuery,
    SelectTagsModal,
    UserInfoModal,
  },
  computed: {
    ...mapState({
      query: state => state.OnlineCommunity.quizQuery,
    }),
  },
  data() {
    return {
      titles: [{ title: '问题列表' }],

      acting: false, // action 按钮
      questionNum: 0,

      originColumns: columns,
      locale: {
        emptyText: () => '暂无数据~',
      },

      sorter: '',
      sortType: '',
    };
  },
  created() {
    this.$store.dispatch('OnlineCommunity/getTagListForSelect');
    this.$store.dispatch('OnlineCommunity/getTopicListForSelect');
    this.onSearch();
  },
  methods: {
    formatDateTime(time) {
      if (!time) {return '--';}
      return Common.formatDateTime(time * 1000, true);
    },

    handleChange(pagination, filters, sorter) {
      this.sortType = '';
      switch (sorter.order) {
        case 'ascend':
          this.sortType = 'desc';
          break;
        case 'descend':
          this.sortType = 'asc';
          break;
      }
      this.sorter = this.sortType ? sorter.columnKey : '';

      this.onSearch();
    },

    async onExport() {
      if (this.downloading) {return;}
      this.downloading = true;

      const params = this.getParams(this.pagination.current, this.pagination.pageSize);
      params.down = 1;

      const data = await getQuizListDownload(params).finally(() => this.downloading = false).catch(() => this.downloading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || `导出失败`);
      }
      this.$message.success(data?.message || '导出成功');
    },

    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (this.data?.length - 1 <= 0 && this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
        this.getData(this.pagination.current > 1 ? (this.pagination.current - 1) : 1, this.pagination.pageSize);
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (this.loading) { return; }
      this.loading = true;

      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = this.getParams(page, pageSize);

      const data = await getQuizList(params).finally(() => this.loading = false);
      if (!data || data?.error_code) {
        return this.$message.error(data?.message || '获取列表失败');
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.questionNum = pagination.total || 0;
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize) {
      const params = { page: page, per_page: pageSize };

      this.query?.quizName && (params.title = this.query.quizName);
      this.query?.answerFilter && (params.answer_count_type = this.query.answerFilter);
      (this.query?.answerCount || this.query?.answerCount === 0) && (params.answer_count = this.query.answerCount);
      if (this.query?.auditingStatus === 1) {
        params.review_status = 0;
      } else if (this.query?.auditingStatus === 2) {
        params.review_status = 1;
      } else {
        params.review_status = -1;
      }
      this.query?.topic && (params.topic_ids = this.query.topic);
      this.query?.tag && (params.label_ids = this.query.tag);
      this.query?.quizUserName && (params.created_user = this.query.quizUserName);
      this.query?.range && this.query.range[0] && (params.created_at_start = this.query.range[0].format('YYYY-MM-DD'));
      this.query?.range && this.query.range[1] && (params.created_at_end = this.query.range[1].format('YYYY-MM-DD'));
      this.sorter && (params.order = `${this.sorter}:${this.sortType}`);

      return params;
    },

    /**
     * 显示问题明细
     * @param record
     */
    showQuizDetail(record) {
      this.$refs?.quizDetailModalRef?.show && this.$refs.quizDetailModalRef.show({ quiz: record });
    },

    /**
     * 删除问题
     * @param record
     */
    deleteRecord(record) {
      this.$confirm({
        content: () => <div>确认删除该问题？</div>,
        onOk: () => this.confirmDeleteRecord(record),
      });
    },
    async confirmDeleteRecord(record) {
      if (this.acting) {return;}
      this.acting = true;

      const data = await deleteQuiz(record.id).finally(() => this.acting = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `问题删除失败`);
        return;
      }
      this.$message.success(data?.message || `问题删除成功`);
      this.onRefreshPage();
    },

    /**
     * 置顶问题/取消置顶
     * @param record
     */
    async setTop(record) {
      if (this.acting) {return;}
      this.acting = true;

      const data = await setQuizTop(record.id).finally(() => this.acting = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || `问题${record.top_at ? '取消' : ''}置顶失败`);
      }
      this.$message.success(data?.message || `问题${record.top_at ? '取消' : ''}置顶成功`);
      this.onRefreshPage();
    },

    /**
     * 查看题主信息
     * @param record
     */
    viewUserInfo(record) {
      this.$refs?.userInfoModalRef?.show && this.$refs.userInfoModalRef.show({ userId: record.created_user_id });
    },

    /**
     * 查看回答
     * @param record
     */
    viewAnswer(record) {
      const path = quizSubPage && quizSubPage[0]?.path || '';
      this.$router.push({ path, query: { qid: record.id } });
    },

    /**
     * 审核通过
     * @param record
     */
    async setAuditing(record) {
      if (this.acting) {return;}
      this.acting = true;

      const data = await setQuizAuditing(record.id).finally(() => this.acting = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || `问题审核失败`);
      }
      this.$message.success(data?.message || `问题审核成功`);
      this.onRefreshPage();
    },
    /**
     * 重置审核
     * @param record
     */
    resetAuditing(record) {
      this.$confirm({
        content: () => <div>是否重置该问题的审核状态？</div>,
        onOk: () => this.confirmResetAuditing(record),
      });
    },
    async confirmResetAuditing(record) {
      if (this.acting) {return;}
      this.acting = true;

      const data = await resetQuizAuditing(record.id).finally(() => this.acting = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || `问题重置审核失败`);
      }
      this.$message.success(data?.message || `问题重置审核成功`);
      this.onRefreshPage();
    },

    /**
     * 添加回答
     * @param record
     */
    setAnswer(record) {
      const path = quizSubPage && quizSubPage[3]?.path || '';
      this.$router.push({ path, query: { qid: record.id } });
    },

    /**
     * 管理标签
     * @param record
     */
    setTag(record) {
      this.$refs?.selectTagsModalRef?.show && this.$refs.selectTagsModalRef.show({
        qid: record.id,
        tags: [],
        tagIds: record.labels_ids,
      });
    },

  },
};
</script>

<style scoped lang="scss">
.oc-quiz {
}
</style>
